import type { Postables } from "@zora/api/concerns/postables/postables.router";
import type { PostableFilters } from "@zora/api/concerns/postables/postables.zod";
import { axios, buildHeaders } from "./axios";

export const allPostables = async (filters?: PostableFilters) => {
  return axios
    .post<Postables>("/postables/all", filters, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};
