import { createFileRoute } from "@tanstack/react-router";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  AlignLeft,
  CheckSquare,
  ChevronDown,
  GripVertical,
  List,
  Type,
} from "lucide-react";
import { useState } from "react";
import { z } from "zod";

const QuestionSchema = z.object({
  id: z.string(),
  type: z.enum(["short", "paragraph", "multiple", "checkbox", "dropdown"]),
  title: z.string().min(1, "Question title is required"),
  options: z.array(z.string()).optional(),
});

type Question = z.infer<typeof QuestionSchema>;

const FormSchema = z.object({
  title: z.string().min(1, "Form title is required"),
  description: z.string(),
  questions: z.array(QuestionSchema),
});

function QuestionTypeIcon({ type }: { type: Question["type"] }) {
  switch (type) {
    case "short":
      return <Type className="h-5 w-5 text-blue-500" />;
    case "paragraph":
      return <AlignLeft className="h-5 w-5 text-green-500" />;
    case "multiple":
      return <List className="h-5 w-5 text-purple-500" />;
    case "checkbox":
      return <CheckSquare className="h-5 w-5 text-orange-500" />;
    case "dropdown":
      return <ChevronDown className="h-5 w-5 text-red-500" />;
    default:
      return null;
  }
}

function SortableQuestion({
  question,
  updateQuestion,
}: {
  question: Question;
  updateQuestion: (id: string, updates: Partial<Question>) => void;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: question.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Card
      ref={setNodeRef}
      style={style}
      className="mb-4 border-2 border-gray-200"
    >
      <CardHeader className="flex flex-row items-center bg-gray-50">
        <div {...attributes} {...listeners}>
          <GripVertical className="mr-2 text-gray-500 cursor-move" />
        </div>
        <QuestionTypeIcon type={question.type} />
        <CardTitle className="flex-grow ml-2">
          <Input
            value={question.title}
            onChange={(e) =>
              updateQuestion(question.id, { title: e.target.value })
            }
            placeholder="Question Title"
            className="font-bold"
          />
        </CardTitle>
        <Select
          value={question.type}
          onValueChange={(value: Question["type"]) =>
            updateQuestion(question.id, { type: value })
          }
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Question Type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="short">Short Answer</SelectItem>
            <SelectItem value="paragraph">Paragraph</SelectItem>
            <SelectItem value="multiple">Multiple Choice</SelectItem>
            <SelectItem value="checkbox">Checkbox</SelectItem>
            <SelectItem value="dropdown">Dropdown</SelectItem>
          </SelectContent>
        </Select>
      </CardHeader>
      <CardContent>
        {(question.type === "multiple" ||
          question.type === "checkbox" ||
          question.type === "dropdown") && (
          <div className="space-y-2">
            {question.options?.map((option, optionIndex) => (
              <Input
                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                key={optionIndex}
                value={option}
                onChange={(e) => {
                  const newOptions = [...(question.options || [])];
                  newOptions[optionIndex] = e.target.value;
                  updateQuestion(question.id, { options: newOptions });
                }}
                placeholder={`Option ${optionIndex + 1}`}
                className="border-gray-300"
              />
            ))}
            <Button
              onClick={() =>
                updateQuestion(question.id, {
                  options: [...(question.options || []), ""],
                })
              }
              variant="outline"
              className="mt-2"
            >
              Add Option
            </Button>
          </div>
        )}
      </CardContent>
    </Card>
  );
}

export default function FormBuilder() {
  const [formState, setFormState] = useState({
    title: "Untitled form",
    description: "",
    questions: [] as Question[],
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const addQuestion = (type: Question["type"]) => {
    const newQuestion: Question = {
      id: Date.now().toString(),
      type,
      title: "Untitled Question",
      options:
        type === "multiple" || type === "checkbox" || type === "dropdown"
          ? ["Option 1"]
          : undefined,
    };
    setFormState((prev) => ({
      ...prev,
      questions: [...prev.questions, newQuestion],
    }));
  };

  const updateQuestion = (id: string, updates: Partial<Question>) => {
    setFormState((prev) => ({
      ...prev,
      questions: prev.questions.map((q) =>
        q.id === id ? { ...q, ...updates } : q,
      ),
    }));
  };

  // biome-ignore lint: @typescript-eslint/no-explicit-any
  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setFormState((prev) => {
        const oldIndex = prev.questions.findIndex((q) => q.id === active.id);
        const newIndex = prev.questions.findIndex((q) => q.id === over.id);

        return {
          ...prev,
          questions: arrayMove(prev.questions, oldIndex, newIndex),
        };
      });
    }
  };

  const handleSave = () => {
    const result = FormSchema.safeParse(formState);
    if (result.success) {
      console.log("Form is valid:", result.data);
      // Here you would typically send the data to your backend
    } else {
      console.error("Form is invalid:", result.error);
      // Here you would typically show error messages to the user
    }
  };

  return (
    <div className="container mx-auto p-4 bg-white shadow-lg rounded-lg">
      <Card className="mb-6 border-2 border-gray-200">
        <CardHeader className="bg-gray-50">
          <Input
            value={formState.title}
            onChange={(e) =>
              setFormState((prev) => ({ ...prev, title: e.target.value }))
            }
            className="text-2xl font-bold mb-2"
            placeholder="Form Title"
          />
          <Textarea
            value={formState.description}
            onChange={(e) =>
              setFormState((prev) => ({ ...prev, description: e.target.value }))
            }
            placeholder="Form Description"
            className="text-sm text-muted-foreground"
          />
        </CardHeader>
      </Card>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={formState.questions}
          strategy={verticalListSortingStrategy}
        >
          {formState.questions.map((question) => (
            <SortableQuestion
              key={question.id}
              question={question}
              updateQuestion={updateQuestion}
            />
          ))}
        </SortableContext>
      </DndContext>

      <div className="mt-6 space-x-2 bg-gray-100 p-4 rounded-lg">
        <Button
          onClick={() => addQuestion("short")}
          variant="outline"
          className="bg-white"
        >
          <Type className="mr-2 h-4 w-4 text-blue-500" />
          Short Answer
        </Button>
        <Button
          onClick={() => addQuestion("paragraph")}
          variant="outline"
          className="bg-white"
        >
          <AlignLeft className="mr-2 h-4 w-4 text-green-500" />
          Paragraph
        </Button>
        <Button
          onClick={() => addQuestion("multiple")}
          variant="outline"
          className="bg-white"
        >
          <List className="mr-2 h-4 w-4 text-purple-500" />
          Multiple Choice
        </Button>
        <Button
          onClick={() => addQuestion("checkbox")}
          variant="outline"
          className="bg-white"
        >
          <CheckSquare className="mr-2 h-4 w-4 text-orange-500" />
          Checkbox
        </Button>
        <Button
          onClick={() => addQuestion("dropdown")}
          variant="outline"
          className="bg-white"
        >
          <ChevronDown className="mr-2 h-4 w-4 text-red-500" />
          Dropdown
        </Button>
      </div>

      <Button
        onClick={handleSave}
        className="mt-6 bg-blue-600 hover:bg-blue-700 text-white"
      >
        Save Form
      </Button>
    </div>
  );
}

export const Route = createFileRoute("/_authenticated/surveys/new")({
  component: () => <FormBuilder />,
});
