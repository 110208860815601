import { Toaster } from "@/components/ui/sonner";
import { Outlet, createFileRoute } from "@tanstack/react-router";
import { MainNav } from "../components/layout/main-nav";
import { Search } from "../components/layout/search";
import TeamSwitcher from "../components/layout/team-switcher";
import { UserNav } from "../components/layout/user-nav";

export const Route = createFileRoute("/_layout")({
  component: LayoutComponent,
});

function LayoutComponent() {
  return (
    <div className="flex-col md:flex">
      <div className="border-b">
        <div className="flex h-16 items-center px-4">
          <TeamSwitcher />
          <MainNav className="mx-6" />
          <div className="ml-auto flex items-center space-x-4">
            <Search />
            <UserNav />
          </div>
        </div>
      </div>
      <Outlet />
      <Toaster />
    </div>
  );
}
