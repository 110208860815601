import PlacholderUser from "@/assets/placeholder-user.webp";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { useAuth } from "@/hooks/useAuth";
import { Link, useMatches, useNavigate } from "@tanstack/react-router";
import {
  AlertCircle,
  Home,
  LineChart,
  Package,
  Package2,
  PanelLeft,
  ShoppingCart,
  Users2,
} from "lucide-react";
import { Fragment } from "react";

type RouteConfig = {
  [key: string]: string;
};

const routeConfig: RouteConfig = {
  "/home": "Home",
  "/feed": "Feed",
  "/events": "Events",
  "/posts": "Posts",
  "/stories": "Stories",
  "/residents": "Residents",
  "/links": "Links",
  "/incidents": "Incidents",
};

function titleCaseWithSpaces(str: string) {
  return str
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function Breadcrumbs() {
  const matches = useMatches();
  const lastMatch = matches[matches.length - 1];
  const pathParts = lastMatch.pathname.split("/").filter(Boolean);

  const breadcrumbs = pathParts.map((part: string, partIndex: number) => {
    const pathUpToPart = `/${pathParts.slice(0, partIndex + 1).join("/")}`;
    let routeName = routeConfig[pathUpToPart] || titleCaseWithSpaces(part);
    const isCUID = /^[A-Za-z0-9]{25,}$/i.test(routeName);
    if (isCUID) {
      routeName = "Edit";
    }

    const isLast = partIndex === pathParts.length - 1;
    const key = `breadcrumb-${part}-${partIndex}`;
    return (
      <Fragment key={key}>
        <BreadcrumbItem>
          {!isLast ? (
            <BreadcrumbLink asChild>
              <Link to={pathUpToPart}>{routeName}</Link>
            </BreadcrumbLink>
          ) : (
            <span>{routeName}</span>
          )}
        </BreadcrumbItem>
        {!isLast && (
          <BreadcrumbSeparator
            key={`separator-${part}-${
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              partIndex
            }`}
          />
        )}
      </Fragment>
    );
  });

  return (
    <Breadcrumb className="hidden md:flex">
      <BreadcrumbList>{breadcrumbs}</BreadcrumbList>
    </Breadcrumb>
  );
}
export function TopBar() {
  const navigate = useNavigate();
  const { claims, logout } = useAuth();
  return (
    <header className="sticky top-0 z-30 flex h-14 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
      <Sheet>
        <SheetTrigger asChild>
          <Button size="icon" variant="outline" className="sm:hidden">
            <PanelLeft className="h-5 w-5" />
            <span className="sr-only">Toggle Menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent side="left" className="sm:max-w-xs">
          <nav className="grid gap-6 text-lg font-medium">
            <Link
              href="#"
              className="group flex h-10 w-10 shrink-0 items-center justify-center gap-2 rounded-full bg-primary text-lg font-semibold text-primary-foreground md:text-base"
            >
              <Package2 className="h-5 w-5 transition-all group-hover:scale-110" />
              <span className="sr-only">Acme Inc</span>
            </Link>
            <Link
              href="#"
              className="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground"
            >
              <Home className="h-5 w-5" />
              Dashboard
            </Link>
            <Link
              href="#"
              className="flex items-center gap-4 px-2.5 text-foreground"
            >
              <ShoppingCart className="h-5 w-5" />
              Orders
            </Link>
            <Link
              href="#"
              className="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground"
            >
              <Package className="h-5 w-5" />
              Products
            </Link>
            <Link
              href="#"
              className="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground"
            >
              <Users2 className="h-5 w-5" />
              Customers
            </Link>
            <Link
              href="#"
              className="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground"
            >
              <AlertCircle className="h-5 w-5" />
              Incidents
            </Link>
            <Link
              href="#"
              className="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground"
            >
              <LineChart className="h-5 w-5" />
              Settings
            </Link>
          </nav>
        </SheetContent>
      </Sheet>
      <Breadcrumbs />
      {/* <Breadcrumb className="hidden md:flex">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link href="#">Feed</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link href="#">Categories</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb> */}
      <div className="relative ml-auto flex-1 md:grow-0">
        {/* <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
        <Input
          type="search"
          placeholder="Search..."
          className="w-full rounded-lg bg-background pl-8 md:w-[200px] lg:w-[320px]"
        /> */}
      </div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="outline"
            size="icon"
            className="overflow-hidden rounded-full"
          >
            <img
              src={PlacholderUser}
              width={36}
              height={36}
              alt="Avatar"
              className="overflow-hidden rounded-full"
            />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          {claims?.email || claims?.orgName ? (
            <>
              <DropdownMenuLabel>
                <div className="flex flex-col">
                  <span>{claims?.orgName}</span>
                  <span className="text-xs text-muted-foreground">
                    {claims?.email}
                  </span>
                </div>
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
            </>
          ) : null}

          <DropdownMenuItem
            onClick={() => {
              navigate({ to: "/settings/users" });
            }}
          >
            Settings
          </DropdownMenuItem>
          {/* <DropdownMenuLabel>My Account</DropdownMenuLabel> */}
          <DropdownMenuSeparator />
          {/* {* <DropdownMenuItem>Settings</DropdownMenuItem>
          <DropdownMenuItem>Support</DropdownMenuItem> */}
          {/* <DropdownMenuSeparator /> */}
          <DropdownMenuItem
            onClick={() => {
              logout();
              navigate({ to: "/login" });
            }}
          >
            Logout
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </header>
  );
}
