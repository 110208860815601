import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Link, createFileRoute } from "@tanstack/react-router";

function App() {
  return (
    <div className="p-4">
      <p className="text-gray-600 mb-6">
        Customize your app experience. Organize your home screen and access your
        favorite web content quickly.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <Card>
          <CardHeader>
            <CardTitle>Feed</CardTitle>
            <CardDescription>
              Organize your mobile app categories on the home tab
            </CardDescription>
          </CardHeader>
          <CardContent>
            <p className="mb-4">
              Customize your app's home screen by organizing and categorizing
              your apps.
            </p>
            <Link to="/app/feed">
              <Button>Manage Feed</Button>
            </Link>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Quick Access</CardTitle>
            <CardDescription>
              Link and pin your web content for easy access
            </CardDescription>
          </CardHeader>
          <CardContent>
            <p className="mb-4">
              Add your favorite websites and web apps for instant access within
              the app.
            </p>
            <Link to="/app/quick-access">
              <Button>Manage Quick Access</Button>
            </Link>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export const Route = createFileRoute("/_authenticated/app/")({
  component: App,
});
