import { deleteEvent, eventsQueryOptions } from "@/api/events.api";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import type { ZoraEvent } from "@zora/api/concerns/mobile/mobile.router";
import { MoreHorizontal } from "lucide-react";
import { useState } from "react";
import { ConfirmModal } from "../ui/confirm-modal";

function EventRow({
  event,
  navigate,
  setDeleteEventId,
}: {
  event: ZoraEvent;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  navigate: any;
  setDeleteEventId: (id: string | null) => void;
}) {
  return (
    <TableRow>
      <TableCell className="font-medium">{event.title}</TableCell>
      <TableCell>{new Date(event.start).toLocaleString()}</TableCell>
      <TableCell>{new Date(event.end).toLocaleString()}</TableCell>
      <TableCell>{event.allDay ? "Yes" : "No"}</TableCell>
      <TableCell>{event.description}</TableCell>
      <TableCell>
        {event.imageUrl ? (
          <img src={event.imageUrl} alt="Event" className="w-6 h-6" />
        ) : null}
      </TableCell>
      <TableCell>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button aria-haspopup="true" size="icon" variant="ghost">
              <MoreHorizontal className="h-4 w-4" />
              <span className="sr-only">Toggle menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuItem
              onClick={() => {
                navigate({
                  to: `/calendar/default/events/${event.id}`,
                });
              }}
            >
              Edit
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => setDeleteEventId(event.id)}>
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </TableCell>
    </TableRow>
  );
}

export default function EventsTable() {
  const eventsQuery = useSuspenseQuery(eventsQueryOptions);
  const events = eventsQuery.data;
  const [deleteEventId, setDeleteEventId] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const { mutate: deleteEventMutation, isPending: isLoading } = useMutation({
    mutationFn: deleteEvent,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["events"] });
      setDeleteEventId(null);
    },
  });

  const navigate = useNavigate();

  return (
    <Card>
      <CardHeader>
        <CardTitle>Events</CardTitle>
        <CardDescription>
          Manage your upcoming events and activities.
        </CardDescription>
      </CardHeader>
      <ConfirmModal
        open={Boolean(deleteEventId)}
        onConfirm={() => {
          if (deleteEventId) deleteEventMutation(deleteEventId);
        }}
        onDismiss={() => {
          setDeleteEventId(null);
        }}
        isLoading={isLoading}
      />
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Title</TableHead>
              <TableHead>Start</TableHead>
              <TableHead>End</TableHead>
              <TableHead>All Day</TableHead>
              <TableHead>Description</TableHead>
              <TableHead>Image</TableHead>
              <TableHead>
                <span className="sr-only">Actions</span>
              </TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {events.map((event) => (
              <EventRow
                key={event.id}
                event={event}
                navigate={navigate}
                setDeleteEventId={setDeleteEventId}
              />
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <CardFooter>
        <div className="text-xs text-muted-foreground">
          Showing <strong>1-{events.length}</strong> of{" "}
          <strong>{events.length}</strong> events
        </div>
      </CardFooter>
    </Card>
  );
}
