import { queryOptions } from "@tanstack/react-query";
import type { Audiences } from "@zora/api/concerns/audiences/audience.router";
import { axios, buildHeaders } from "./axios";

export const residentsQueryOptions = queryOptions({
  queryKey: ["residents"],
  queryFn: () => fetchResidents(),
});

export const fetchResidents = async () => {
  return axios
    .get<Audiences>("/audiences", {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};
