import { cn } from "@/lib/utils";

import { MoreHorizontal, PenSquare, Phone } from "lucide-react";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

import { fetchConversations } from "@/api/conversations.api";
import { useQuery } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";
import {
  NewMessageCommand,
  useNewMessageCommand,
} from "../conversations/new-convo-command";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { buttonVariants } from "../ui/button-shared";
import type { Message } from "./data";

interface SidebarProps {
  isCollapsed: boolean;
  chats: {
    id: string;
    name: string;
    messages: Message[];
    avatar?: string;
    variant: "secondary" | "ghost";
  }[];
  isMobile: boolean;
  participantId: string;
  participantType: "phone" | "user" | "organization";
}

export function Sidebar({
  chats,
  isCollapsed,
  // biome-ignore lint/correctness/noUnusedVariables: <explanation>
  isMobile,
  // biome-ignore lint/correctness/noUnusedVariables: <explanation>
  participantId,
  // biome-ignore lint/correctness/noUnusedVariables: <explanation>
  participantType,
}: SidebarProps) {
  const setIsOpen = useNewMessageCommand((state) => state.setIsOpen);

  return (
    <>
      <NewMessageCommand />
      <div
        data-collapsed={isCollapsed}
        className="relative group flex flex-col h-full bg-muted/10 dark:bg-muted/20 gap-4 p-2 data-[collapsed=true]:p-2 "
      >
        {!isCollapsed && (
          <div className="flex justify-between p-2 items-center">
            <div className="flex gap-2 items-center text-2xl">
              <p className="font-medium">Chats</p>
              <span className="text-zinc-300">({chats.length})</span>
            </div>

            <div>
              <Link
                href=""
                className={cn(
                  buttonVariants({ variant: "ghost", size: "icon" }),
                  "h-9 w-9",
                )}
              >
                <MoreHorizontal size={20} />
              </Link>

              <Link
                onClick={() => {
                  setIsOpen(true);
                }}
                className={cn(
                  buttonVariants({ variant: "ghost", size: "icon" }),
                  "h-9 w-9",
                )}
              >
                <PenSquare size={20} />
              </Link>
            </div>
          </div>
        )}
        <nav className="grid gap-1 px-2 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2">
          {chats.map((chat, index) =>
            isCollapsed ? (
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              <TooltipProvider key={index}>
                {/* biome-ignore lint/suspicious/noArrayIndexKey: <explanation> */}
                <Tooltip key={index} delayDuration={0}>
                  <TooltipTrigger asChild>
                    <Link
                      to={`/conversations/${chat.id}`}
                      className={cn(
                        buttonVariants({ variant: chat.variant, size: "icon" }),
                        "h-11 w-11 md:h-16 md:w-16",
                        chat.variant === "secondary" &&
                          "dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white",
                      )}
                    >
                      <Avatar className="flex justify-center items-center">
                        <AvatarImage
                          src={chat.avatar}
                          alt={chat.avatar}
                          width={6}
                          height={6}
                          className="w-10 h-10 "
                        />
                        <AvatarFallback>
                          <Phone className="w-6 h-6" />
                        </AvatarFallback>
                      </Avatar>

                      <span className="sr-only">{chat.name}</span>
                    </Link>
                  </TooltipTrigger>
                  <TooltipContent
                    side="right"
                    className="flex items-center gap-4"
                  >
                    {chat.name}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ) : (
              <Link
                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                key={index}
                to={`/conversations/${chat.id}`}
                className={cn(
                  // supposed to be xl?
                  buttonVariants({ variant: chat.variant, size: "lg" }),
                  chat.variant === "secondary" &&
                    "dark:bg-muted dark:text-white dark:hover:bg-muted dark:hover:text-white shrink",
                  "justify-start gap-4",
                )}
              >
                <Avatar className="flex justify-center items-center">
                  <AvatarImage
                    src={chat.avatar}
                    alt={chat.avatar}
                    width={6}
                    height={6}
                    className="w-10 h-10 "
                  />
                  <AvatarFallback>
                    <Phone className="w-6 h-6" />
                  </AvatarFallback>
                </Avatar>
                <div className="flex flex-col max-w-28">
                  <span>{chat.name}</span>
                  {chat.messages.length > 0 && (
                    <span className="text-zinc-300 text-xs truncate ">
                      {
                        chat.messages[chat.messages.length - 1].name.split(
                          " ",
                        )[0]
                      }
                      :{" "}
                      {chat.messages[chat.messages.length - 1].isLoading
                        ? "Typing..."
                        : chat.messages[chat.messages.length - 1].message}
                    </span>
                  )}
                </div>
              </Link>
            ),
          )}
        </nav>
      </div>
    </>
  );
}
