import { organizationQueryOptions } from "@/api/organizations.api";
import { OrganizationForm } from "@/components/organizations/organizations-form";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

function EditOrganization() {
  const { organizationId } = Route.useParams();
  const { data: organization } = useSuspenseQuery(
    organizationQueryOptions(organizationId),
  );

  return (
    <OrganizationForm
      isEdit={true}
      initialValues={{
        id: organization.id,
        name: organization.name,
        parentId: organization.parentId,
        city: organization.city,
        state: organization.state,
      }}
    />
  );
}

export const Route = createFileRoute(
  "/_authenticated/super-admin/organizations/$organizationId/edit",
)({
  component: EditOrganization,
});
