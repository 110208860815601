import { Button } from "@/components/ui/button";
import type { Table } from "@tanstack/react-table";
import React from "react";

type PaginationProps<TData> = {
  table: Table<TData>;
};

export function Pagination<TData>({ table }: PaginationProps<TData>) {
  return (
    <div className="flex items-center justify-between">
      <div className="text-xs text-muted-foreground">
        Showing <strong>{table.getState().pagination.pageIndex + 1}</strong> of{" "}
        <strong>{table.getPageCount()}</strong> pages
      </div>
      <div className="flex items-center space-x-2 ml-2">
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          Previous
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
