import { queryOptions } from "@tanstack/react-query";
import type { User } from "@zora/api/prisma/generated";
import { axios, buildHeaders } from "./axios";

export const usersQueryOptions = queryOptions({
  queryKey: ["users"],
  queryFn: () => fetchUsers(),
});

export const fetchUsers = async (): Promise<User[]> => {
  return axios
    .get<User[]>("/users", {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const inviteUser = async (data: { email: string }) => {
  return axios
    .post<User>("/users/invite", data, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

// Add the deleteUser function
export const deleteUser = async (memberId: string): Promise<void> => {
  return axios
    .delete(`/users/${memberId}`, {
      headers: buildHeaders(),
    })
    .then(() => undefined);
};
