import { queryOptions } from "@tanstack/react-query";
import type {
  ConversationItemResponse,
  ConversationResponse,
  ConversationsResponse,
} from "@zora/api/concerns/conversations/conversations.router";
import type { ParticipantType } from "@zora/api/concerns/conversations/conversations.zod";
import { axios, buildHeaders } from "./axios";

export const orgPhoneNumberQueryOptions = (orgId: string) =>
  queryOptions({
    queryKey: ["orgPhoneNumber", orgId],
    queryFn: ({ queryKey }) => fetchOrgPhoneNumber(queryKey[1] as string),
  });

export const fetchOrgPhoneNumber = async (orgId: string) => {
  const response = await axios.get<string>(
    `/organizations/phone-number/${orgId}`,
    {
      headers: buildHeaders(),
    },
  );
  return response.data;
};

export const fetchConversations = async ({
  participantId,
  participantType,
}: {
  participantId: string;
  participantType: ParticipantType;
}): Promise<ConversationsResponse> => {
  const encodedParticipantId = encodeURIComponent(participantId);
  const encodedParticipantType = encodeURIComponent(participantType);

  const response = await axios.get<ConversationsResponse>(
    `/conversations/?participantId=${encodedParticipantId}&participantType=${encodedParticipantType}`,
    {
      headers: buildHeaders(),
    },
  );
  return response.data;
};

export const fetchConversation = async ({
  conversationId,
}: {
  conversationId: string;
}) => {
  const response = await axios.get<ConversationResponse>(
    `/conversations/${conversationId}`,
    {
      headers: buildHeaders(),
    },
  );
  return response.data;
};

export const createConversationItem = async ({
  conversationId,
  senderId,
  type,
  textBody,
  json,
}: {
  conversationId: string;
  senderId: string;
  type: string;
  textBody: string;
  // biome-ignore lint: @typescript-eslint/no-explicit-any
  json: Record<string, any>;
}) => {
  const response = await axios.post<ConversationItemResponse>(
    `/conversations/${conversationId}/items`,
    { senderId, type, textBody, json },
    { headers: buildHeaders() },
  );
  return response.data;
};
