import {
  createEvent,
  deleteEvent,
  editEvent,
  eventsQueryOptions,
} from "@/api/events.api";
import { useEventsModal } from "@/hooks/useEventsModal";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { Outlet } from "@tanstack/react-router";
import {
  type EventInput,
  eventZodForm,
} from "@zora/api/concerns/events/events.zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { useDeleteEvent } from "../hooks/useDeleteModal";
import PhonePreview from "./phone-preview/phone-preview";
import { SidebarNav } from "./settings/components/sidebar-nav";
import { Separator } from "./ui/separator";

const sidebarNavItems = [
  {
    title: "Stories",
    href: "/feed/stories",
  },
  {
    title: "Pinned Posts",
    href: "/feed/pinned-posts",
  },
  {
    title: "Posts",
    href: "/feed/posts",
  },
];

const newFeed = true;

export function Feed() {
  const eventsQuery = useSuspenseQuery(eventsQueryOptions);
  const _events = eventsQuery.data;
  const queryClient = useQueryClient();
  const _deleteEventId = useDeleteEvent((s) => s.toDeleteId);
  const setDeleteEventId = useDeleteEvent((s) => s.setToDeleteId);

  const _isEventModalOpen = useEventsModal((s) => s.isOpen);
  const setIsEventModalOpen = useEventsModal((s) => s.setIsModalOpen);
  const setEventId = useEventsModal((s) => s.setEventId);
  const _eventId = useEventsModal((s) => s.eventId);
  const defaultValues = useEventsModal((s) => s.defaultValues);
  const setDefaultValues = useEventsModal((s) => s.setDefaultValues);

  const form = useForm<EventInput>({
    resolver: zodResolver(eventZodForm),
    defaultValues: {
      title: defaultValues?.title || "",
      description: defaultValues?.description || "",
      imageUrl: defaultValues?.imageUrl || "",
      start: defaultValues?.start || new Date().toISOString(),
      end: defaultValues?.end || new Date().toISOString(),
    },
  });

  useEffect(() => {
    if (defaultValues) {
      form.reset(defaultValues);
    }
  }, [form, defaultValues]);

  // biome-ignore lint/correctness/noUnusedVariables: <explanation>
  const { mutate: deleteEventMutation, isPending: isLoading } = useMutation({
    mutationFn: deleteEvent,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["events"] });
      setDeleteEventId(null);
    },
  });

  // biome-ignore lint/correctness/noUnusedVariables: <explanation>
  const { mutate: createEventMutation, isPending: isCreateLoading } =
    useMutation({
      mutationFn: createEvent,
      onSuccess: () => {
        form.reset();
        queryClient.invalidateQueries({ queryKey: ["events"] });
        setEventId(null);
        toast.success("Post created successfully");
        setIsEventModalOpen(false);
      },
    });

  // biome-ignore lint/correctness/noUnusedVariables: <explanation>
  const { mutate: editEventMutation, isPending: isEditLoading } = useMutation({
    mutationFn: editEvent,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["events"] });
      setEventId(null);
      setIsEventModalOpen(false);
      setDefaultValues({});
      form.reset();
    },
  });

  if (newFeed) return <Outlet />;

  return (
    <div className="md:block">
      <div className="space-y-0.5">
        <h2 className="text-2xl font-bold tracking-tight">Feed</h2>
        <p className="text-muted-foreground">
          Organize your feed to showcase your best content.
        </p>
      </div>
      <Separator className="my-6" />
      <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
        <aside className="-mx-4 lg:w-1/6">
          <SidebarNav items={sidebarNavItems} />
        </aside>
        <div className="flex-1 flex-grow">
          <Outlet />
        </div>
        <div>
          <PhonePreview>
            <h1>hi</h1>
          </PhonePreview>
        </div>
      </div>
    </div>
  );
}
