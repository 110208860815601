/* eslint-disable no-var */

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import cuid2 from "@paralleldrive/cuid2";
import { createFileRoute } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { FaFacebook } from "react-icons/fa";

const configId = "1526200024768616";
const FB_APP_ID = "539451608557535";

declare global {
  interface Window {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    FB: any;
    fbAsyncInit: () => void;
  }
}

export function FacebookLogin() {
  const [loginStatus, setLoginStatus] = useState<string>("unknown");

  useEffect(() => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: FB_APP_ID,
        cookie: true,
        xfbml: true,
        version: "v20.0",
      });

      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      window.FB.getLoginStatus((response: any) => {
        setLoginStatus(response.status);
      });
    };

    // Load the SDK asynchronously
    ((d, s, id) => {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      const js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode?.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    return () => {
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      if ((window as any).FB) (window as any).FB = undefined;
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      if ((window as any).fbAsyncInit) (window as any).fbAsyncInit = undefined;
      const script = document.getElementById("facebook-jssdk");
      if (script) script.remove();
    };
  }, []);

  const handleLogin = () => {
    const redirectUri = `${
      import.meta.env.VITE_API_URL
    }/settings/facebook-success`;

    const state = cuid2.createId();
    const params = new URLSearchParams();
    params.append("client_id", FB_APP_ID);
    params.append("redirect_uri", redirectUri);
    params.append("state", state);

    params.append("config_id", configId);
    params.append("response_type", "code");
    params.append("override_default_response_type", "true");

    const loginUrl = `https://www.facebook.com/v20.0/dialog/oauth?${params.toString()}`;
    window.location.href = loginUrl;
  };

  return (
    <Card className="w-[350px] mt-10">
      <CardHeader>
        <CardTitle>Connect with Zora</CardTitle>
        <CardDescription>
          Connect your Facebook page with Zora to enable enhanced features.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Button variant="default" onClick={handleLogin}>
          <FaFacebook size={20} />
          <div className="ml-2">Connect Facebook Page</div>
        </Button>

        <p className="mt-4">Facebook Login Status: {loginStatus}</p>
        {loginStatus === "connected" && (
          <p className="text-green-600 mt-2">Successfully connected!</p>
        )}
      </CardContent>
    </Card>
  );
}

export const Route = createFileRoute("/_public/facebook/login/")({
  component: FacebookLogin,
  validateSearch: (search: Record<string, unknown>) => {
    return {
      business: search.business as string | undefined,
    };
  },
});
