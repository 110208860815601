import {
  createNotification,
  updateNotification,
} from "@/api/notifications.api";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { Textarea } from "@/components/ui/textarea";
import { usePostablesCommandSearch } from "@/hooks/usePostablesCommandSearch";
import { friendlyPostableTitle, friendlyPostableType } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useRouter } from "@tanstack/react-router";
import {
  type NotificationsFormData,
  notificationsFormDataZod,
} from "@zora/api/concerns/notifications/notifications.zod";
import type { Postable } from "@zora/api/concerns/postables/postables.router";
import dayjs from "dayjs";
import { X } from "lucide-react";
import { ChevronLeft } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { PostablesCommandSeach } from "../command/postables-command-search";
import { Badge } from "../ui/badge";
import { usePushTokens } from "./useNotifications";

type NotificationFormProps =
  | {
      isEdit: true;
      initialValues: NotificationsFormData & { id: string };
    }
  | {
      isEdit: false;
      initialValues?: Partial<NotificationsFormData>;
    };

export function NotificationForm({
  isEdit,
  initialValues,
}: NotificationFormProps) {
  const [newPushToken, setNewPushToken] = useState("");
  const pushTokens = usePushTokens((state) => state.pushTokens);
  const addPushToken = usePushTokens((state) => state.addPushToken);
  const removePushToken = usePushTokens((state) => state.removePushToken);
  const router = useRouter();
  const navigate = useNavigate();
  const [sendNow, setSendNow] = useState(true);
  const [linkedItem, setLinkedItem] = useState<Postable | null>(null);
  const setOpen = usePostablesCommandSearch((state) => state.setIsOpen);
  const notificationId = isEdit ? initialValues.id : undefined;
  const queryClient = useQueryClient();

  const form = useForm<NotificationsFormData>({
    resolver: zodResolver(notificationsFormDataZod),
    defaultValues: {
      title: initialValues?.title ?? "",
      body: initialValues?.body ?? "",
      sendAt: initialValues?.sendAt ?? dayjs().utc().toISOString(),
      postable: initialValues?.postable ?? undefined,
    },
  });

  const { mutate: createNotificationMutation } = useMutation({
    mutationFn: (notification: NotificationsFormData) =>
      createNotification(notification),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["notifications"] });
      navigate({ to: "/notifications" });
    },
  });

  const { mutate: updateNotificationMutation } = useMutation({
    mutationFn: (notification: NotificationsFormData & { id: string }) =>
      updateNotification(notification),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["notifications"] });
      if (notificationId)
        queryClient.invalidateQueries({
          queryKey: ["notification", notificationId],
        });
      navigate({ to: "/notifications" });
    },
  });

  const onSubmit = (data: NotificationsFormData) => {
    if (isEdit && notificationId) {
      updateNotificationMutation({
        ...data,
        id: notificationId,
      });
    } else {
      createNotificationMutation({
        ...data,
        pushTokens: pushTokens.length > 0 ? pushTokens : undefined,
      });
    }
  };

  return (
    <div className="mx-auto grid max-w-[59rem] flex-1 auto-rows-max gap-4 mt-4">
      <PostablesCommandSeach
        filters={{
          types: ["basicPost", "event"],
        }}
        onItemSelect={(item: Postable) => {
          setLinkedItem(item);
          form.setValue("postable", {
            ...item,
            organizationId: item.postable.organizationId ?? "",
          });
          setOpen(false);
        }}
        existingIds={linkedItem ? [linkedItem.postableId] : []}
      />

      <Form {...form}>
        <div className="flex items-center gap-4">
          <Button
            variant="outline"
            size="icon"
            className="h-7 w-7"
            onClick={() => {
              router.history.back();
            }}
          >
            <ChevronLeft className="h-4 w-4" />
            <span className="sr-only">Back</span>
          </Button>
          <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
            {isEdit ? "Edit Notification" : "New Notification"}
          </h1>
          <div className="hidden items-center gap-2 md:ml-auto md:flex">
            <Button
              type="button"
              variant="outline"
              size="sm"
              onClick={() => {
                router.history.back();
              }}
            >
              Discard
            </Button>
            <Button size="sm" onClick={form.handleSubmit(onSubmit)}>
              Save Notification
            </Button>
          </div>
        </div>

        <div className="grid gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-3 lg:gap-8">
          <div className="grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8">
            <Card>
              <CardHeader>
                <CardTitle>Notification Details</CardTitle>
                <CardDescription>
                  Enter the details for your notification.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid gap-6">
                  <FormField
                    control={form.control}
                    name="title"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Title</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="body"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Body</FormLabel>
                        <FormControl>
                          <Textarea {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Override Push Tokens</CardTitle>
                <CardDescription>
                  Use this to send a notification to specific devices.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <div className="flex space-x-2">
                    <Input
                      placeholder="Enter push token"
                      value={newPushToken}
                      onChange={(e) => setNewPushToken(e.target.value)}
                    />
                    <Button
                      onClick={() => {
                        if (newPushToken) {
                          addPushToken(newPushToken);
                          setNewPushToken("");
                        }
                      }}
                    >
                      Add
                    </Button>
                  </div>
                  <div className="flex flex-wrap gap-2">
                    {pushTokens.map((token) => (
                      <Badge key={token} variant="secondary">
                        {token}
                        <Button
                          variant="ghost"
                          size="sm"
                          className="h-4 w-4 p-0 ml-2"
                          onClick={() => removePushToken(token)}
                        >
                          <X className="h-3 w-3" />
                          <span className="sr-only">Remove</span>
                        </Button>
                      </Badge>
                    ))}
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="grid auto-rows-max items-start gap-4 lg:gap-8">
            <Card>
              <CardHeader>
                <CardTitle>Linked Item</CardTitle>
                <CardDescription>
                  When selected, the resident will be linked to this screen.
                </CardDescription>
              </CardHeader>
              <CardContent>
                {linkedItem ? (
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="font-medium">
                        {friendlyPostableTitle(linkedItem.postable)}
                      </p>
                      <p className="text-sm text-muted-foreground">
                        {friendlyPostableType(linkedItem.postableType)}
                      </p>
                    </div>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => setLinkedItem(null)}
                    >
                      Remove
                    </Button>
                  </div>
                ) : (
                  <>
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => setOpen(true)}
                    >
                      Add Linked Item
                    </Button>
                    {form.formState.errors.postable && (
                      <p className="text-sm text-destructive mt-2">
                        {form.formState.errors.postable.message}
                      </p>
                    )}
                  </>
                )}
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Delivery Options</CardTitle>
                <CardDescription>
                  Choose when to send the notification.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="flex items-center space-x-2">
                  <Switch
                    checked={sendNow}
                    onCheckedChange={setSendNow}
                    id="send-now"
                  />
                  <label htmlFor="send-now" className="text-sm font-medium">
                    Send Now
                  </label>
                </div>
                {!sendNow && (
                  <FormField
                    control={form.control}
                    name="sendAt"
                    render={({ field }) => (
                      <FormItem className="mt-4">
                        <FormLabel>Schedule Send</FormLabel>
                        <FormControl>
                          <Input
                            type="datetime-local"
                            {...field}
                            value={
                              field.value
                                ? dayjs(field.value)
                                    .local()
                                    .format("YYYY-MM-DDTHH:mm")
                                : ""
                            }
                            onChange={(e) => {
                              const localDate = dayjs(e.target.value);
                              const utcDate = localDate.utc().toISOString();
                              field.onChange(utcDate);
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}
              </CardContent>
            </Card>
          </div>
        </div>

        <div className="flex items-center justify-center gap-2 md:hidden mt-4">
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              router.history.back();
            }}
          >
            Discard
          </Button>
          <Button size="sm" onClick={form.handleSubmit(onSubmit)}>
            Save Notification
          </Button>
        </div>
      </Form>
    </div>
  );
}
