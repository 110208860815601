import {
  AiImage,
  AiWriter,
  CodeBlock,
  Figcaption,
  HorizontalRule,
  ImageBlock,
  ImageUpload,
  Link,
} from "@/components/tiptap/extensions";
import { TableOfContentsNode } from "@/components/tiptap/extensions/TableOfContentsNode";
import { isTextSelection } from "@tiptap/core";
import type { Editor } from "@tiptap/react";

export const isTextSelected = ({ editor }: { editor: Editor }) => {
  const {
    state: {
      doc,
      selection,
      selection: { empty, from, to },
    },
  } = editor;

  // Sometime check for `empty` is not enough.
  // Doubleclick an empty paragraph returns a node size of 2.
  // So we check also for an empty text size.
  const isEmptyTextBlock =
    !doc.textBetween(from, to).length && isTextSelection(selection);

  if (empty || isEmptyTextBlock || !editor.isEditable) {
    return false;
  }

  return true;
};

export const isTableGripSelected = (node: HTMLElement) => {
  let container = node;

  while (container && !["TD", "TH"].includes(container.tagName)) {
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    container = container.parentElement!;
  }

  const gripColumn = container?.querySelector?.("a.grip-column.selected");
  const gripRow = container?.querySelector?.("a.grip-row.selected");

  if (gripColumn || gripRow) {
    return true;
  }

  return false;
};

export const isCustomNodeSelected = (editor: Editor, node: HTMLElement) => {
  const customNodes = [
    HorizontalRule.name,
    ImageBlock.name,
    ImageUpload.name,
    CodeBlock.name,
    ImageBlock.name,
    Link.name,
    AiWriter.name,
    AiImage.name,
    Figcaption.name,
    TableOfContentsNode.name,
  ];

  return (
    customNodes.some((type) => editor.isActive(type)) ||
    isTableGripSelected(node)
  );
};
