import { queryOptions } from "@tanstack/react-query";
import type { MediaResponse } from "@zora/api/concerns/media/media.router";
import type { MediaFormData } from "@zora/api/concerns/media/media.zod";
import { axios, buildHeaders } from "./axios";

export const mediaQueryOptions = queryOptions({
  queryKey: ["media"],
  queryFn: () => fetchMedia(),
});

export const mediaItemQueryOptions = (mediaId: string) => ({
  queryKey: ["media", mediaId],
  queryFn: () => fetchMediaItem(mediaId),
});

export const fetchMedia = async (): Promise<MediaResponse[]> => {
  return axios
    .get<MediaResponse[]>("/media", {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const fetchMediaItem = async (
  mediaId: string,
): Promise<MediaResponse> => {
  return axios
    .get<MediaResponse>(`/media/${mediaId}`, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const updateMedia = async ({
  id: mediaId,
  ...media
}: Partial<MediaResponse>): Promise<MediaResponse> => {
  return axios
    .put<MediaResponse>(`/media/${mediaId}`, media, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const createMedia = async (
  media: MediaFormData,
): Promise<MediaResponse> => {
  return axios
    .post<MediaResponse>("/media", media, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const deleteMedia = async (mediaId: string): Promise<void> => {
  return axios
    .delete(`/media/${mediaId}`, {
      headers: buildHeaders(),
    })
    .then(() => undefined);
};
