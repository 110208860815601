import { Polygon } from "@/components/map/components/polygon";
import { createFileRoute } from "@tanstack/react-router";
import {
  APIProvider,
  AdvancedMarker,
  Map as GoogleMap,
  Pin,
  useMap,
} from "@vis.gl/react-google-maps";
import { useEffect, useMemo, useState } from "react";

const center = { lat: 40.3057, lng: -111.7495 };

// Utility function to create a square neighborhood
function createSquareNeighborhood(
  name: string,
  centerLat: number,
  centerLng: number,
  size: number,
) {
  const halfSize = size / 2;
  return {
    name,
    coordinates: [
      { lat: centerLat - halfSize, lng: centerLng - halfSize },
      { lat: centerLat - halfSize, lng: centerLng + halfSize },
      { lat: centerLat + halfSize, lng: centerLng + halfSize },
      { lat: centerLat + halfSize, lng: centerLng - halfSize },
    ],
  };
}

// Create neighborhoods
const neighborhoods = [
  createSquareNeighborhood("North", center.lat + 0.01, center.lng, 0.01),
  createSquareNeighborhood("East", center.lat, center.lng + 0.01, 0.01),
  createSquareNeighborhood("South", center.lat - 0.01, center.lng, 0.01),
  createSquareNeighborhood("West", center.lat, center.lng - 0.01, 0.01),
];

// Utility function to encode polygon coordinates
function encodePolygon(coordinates: { lat: number; lng: number }[]) {
  if (typeof google === "undefined" || !google.maps || !google.maps.geometry) {
    console.error("Google Maps API not loaded");
    return null;
  }
  return google.maps.geometry.encoding.encodePath(
    coordinates.map((coord) => new google.maps.LatLng(coord.lat, coord.lng)),
  );
}

function MapContent() {
  const [googleLoaded, setGoogleLoaded] = useState(false);
  // biome-ignore lint: @typescript-eslint/no-unused-vars
  const [mapCenter, setMapCenter] = useState(center);
  const map = useMap();

  useEffect(() => {
    if (map && window.google && window.google.maps.geometry) {
      setGoogleLoaded(true);
    }
  }, [map]);

  const encodedNeighborhoods = useMemo(() => {
    if (!googleLoaded) return [];

    return neighborhoods.map((neighborhood) => ({
      ...neighborhood,
      encodedPath: encodePolygon(neighborhood.coordinates),
      fillColor: `#${Math.floor(Math.random() * 16777215).toString(16)}33`,
      strokeColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
      strokeWeight: 2,
    }));
  }, [googleLoaded]);

  return (
    <GoogleMap
      mapId="fac30070c93eb8d9"
      defaultCenter={center}
      defaultZoom={14}
      gestureHandling={"greedy"}
      disableDefaultUI={true}
      style={{ width: "100%", height: "100%" }}
    >
      <AdvancedMarker position={center}>
        <Pin
          background={"#22C55E"}
          borderColor={"#15803D"}
          glyphColor={"#FFFFFF"}
        />
      </AdvancedMarker>

      {encodedNeighborhoods.map(
        (neighborhood, index) =>
          neighborhood.encodedPath && (
            <Polygon
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              key={index}
              encodedPaths={[neighborhood.encodedPath]}
              fillColor={neighborhood.fillColor}
              strokeColor={neighborhood.strokeColor}
              strokeWeight={neighborhood.strokeWeight}
              onClick={() => console.log(`Clicked on ${neighborhood.name}`)}
            />
          ),
      )}
      {/* Add a visible marker at the center for debugging */}
      <AdvancedMarker position={mapCenter}>
        <Pin
          background={"#0000FF"}
          borderColor={"#000080"}
          glyphColor={"#FFFFFF"}
        />
      </AdvancedMarker>
    </GoogleMap>
  );
}

function ResidentsMap() {
  // biome-ignore lint: @typescript-eslint/no-unused-vars
  const [searchQuery, setSearchQuery] = useState("");
  // biome-ignore lint: @typescript-eslint/no-unused-vars
  const [filters, setFilters] = useState({
    showResidents: true,
    showNeighborhoods: true,
  });

  // biome-ignore lint: @typescript-eslint/no-unused-vars
  const handleSearch = () => {
    // Implement search functionality
    console.log("Searching for:", searchQuery);
  };

  return (
    <APIProvider
      apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
      libraries={["geometry"]}
    >
      <div className="flex h-screen">
        {/* <Sheet>
          <SheetTrigger asChild>
            <Button variant="outline" className="fixed left-4 top-4 z-10">
              Filters
            </Button>
          </SheetTrigger>
          <SheetContent side="left">
            <div className="space-y-4">
              <Input
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <Button onClick={handleSearch}>Search</Button>
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="showResidents"
                  checked={filters.showResidents}
                  onCheckedChange={(checked) =>
                    setFilters({ ...filters, showResidents: !!checked })
                  }
                />
                <label htmlFor="showResidents">Show Residents</label>
              </div>
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="showNeighborhoods"
                  checked={filters.showNeighborhoods}
                  onCheckedChange={(checked) =>
                    setFilters({ ...filters, showNeighborhoods: !!checked })
                  }
                />
                <label htmlFor="showNeighborhoods">Show Neighborhoods</label>
              </div>
            </div>
          </SheetContent>
        </Sheet> */}
        <div className="flex-1 relative">
          <MapContent />
        </div>
      </div>
    </APIProvider>
  );
}

export const Route = createFileRoute("/_authenticated/residents/map")({
  component: () => <ResidentsMap />,
});
