import { queryOptions } from "@tanstack/react-query";
import type { Stories, Story } from "@zora/api/concerns/mobile/mobile.router";
import type {
  CreateStoryInput,
  EditStoryInput,
} from "@zora/api/concerns/stories/stories.zod";
import { axios, buildHeaders } from "./axios";

export const storiesQueryOptions = queryOptions({
  queryKey: ["stories"],
  queryFn: () => fetchStories(),
});

export const fetchStories = async () => {
  return axios
    .get<Stories>("/stories", {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const editStory = async (data: EditStoryInput) => {
  return axios
    .put<Story>(`/stories/${data.storyId}`, data, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const createStory = async (data: CreateStoryInput) => {
  return axios
    .post<Story>("/stories", data, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const deleteStory = async (id: string) => {
  return axios
    .delete<Story>(`/stories/${id}`, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};
