import { organizationsQueryOptions } from "@/api/organizations.api";
import { TableHeader } from "@/components/tables/add-header";
import type { DropdownItem } from "@/components/tables/data-row";
import { DataTable } from "@/components/tables/data-table";
import { useAuth } from "@/hooks/useAuth";
import type { ColumnDefWithAccessor } from "@/utils/types";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import type { Organization } from "@zora/api/prisma/generated";

export const Route = createFileRoute(
  "/_authenticated/super-admin/organizations/",
)({
  component: () => <OrganizationsRoute />,
});

const columns: ColumnDefWithAccessor<Organization>[] = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "name",
    header: "Name",
  },
  {
    accessorKey: "city",
    header: "City",
  },
  {
    accessorKey: "createdAt",
    header: "Created At",
  },
];

function OrganizationsRoute() {
  const organizationsQuery = useSuspenseQuery(organizationsQueryOptions);
  const organizations = organizationsQuery.data;
  const setClaims = useAuth((state) => state.setClaims);
  const navigate = useNavigate();

  const dropdownItems: DropdownItem<Organization>[] = [
    {
      label: "Edit",
      onClick: (organization) => {
        navigate({
          to: "/super-admin/organizations/$organizationId/edit",
          params: { organizationId: organization.id },
        });
      },
    },
    {
      label: "Delete",
      onClick: () => {
        /* Delete logic */
      },
    },
    {
      label: "Impersonate",
      onClick: ({ id, name }) => {
        setClaims({
          userId: "admin",
          type: "user",
          orgId: id,
          organizations: [{ id, name }],
          email: "impersonate@zora.com",
          orgName: name,
        });
        navigate({
          to: "/app",
        });
      },
    },
  ];

  return (
    <div>
      <TableHeader
        linkTo="/super-admin/organizations/new"
        buttonText="Add Organization"
      />
      <DataTable
        data={organizations}
        columns={columns}
        title="Organizations"
        description="Manage your organizations"
        dropdownItems={dropdownItems}
      />
    </div>
  );
}
