import { queryOptions } from "@tanstack/react-query";
import type {
  Incident,
  Incidents,
} from "@zora/api/concerns/incidents/incidents.router";
import { axios, buildHeaders } from "./axios";

export const incidentsQueryOptions = queryOptions({
  queryKey: ["incidents"],
  queryFn: () => fetchIncidents(),
});

export const incidentQueryOptions = (incidentId: string) => ({
  queryKey: ["incident", incidentId],
  queryFn: () => fetchIncident(incidentId),
});

export const fetchIncidents = async () => {
  return axios
    .get<Incidents>("/incidents", {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const fetchIncident = async (incidentId: string) => {
  return axios
    .get<Incident>(`/incidents/${incidentId}`, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const updateIncident = async ({
  id: incidentId,
  ...incident
}: Partial<Incident>) => {
  return axios
    .put<Incident>(`/incidents/${incidentId}`, incident, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const deleteIncident = async (incidentId: string) => {
  return axios
    .delete<void>(`/incidents/${incidentId}`, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};
