import { queryOptions } from "@tanstack/react-query";
import type { NotificationResponse } from "@zora/api/concerns/notifications/notifications.router";
import type { NotificationsFormData } from "@zora/api/concerns/notifications/notifications.zod";
import type { Notification } from "@zora/api/prisma/generated";
import { axios, buildHeaders } from "./axios";

export const notificationsQueryOptions = queryOptions({
  queryKey: ["notifications"],
  queryFn: () => fetchNotifications(),
});

export const notificationQueryOptions = (notificationId: string) => ({
  queryKey: ["notification", notificationId],
  queryFn: () => fetchNotification(notificationId),
});

export const fetchNotifications = async (): Promise<NotificationResponse> => {
  return axios
    .get<NotificationResponse>("/notifications", {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const fetchNotification = async (
  notificationId: string,
): Promise<Notification> => {
  return axios
    .get<Notification>(`/notifications/${notificationId}`, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const updateNotification = async ({
  id: notificationId,
  ...notification
}: Partial<Notification>): Promise<Notification> => {
  return axios
    .put<Notification>(`/notifications/${notificationId}`, notification, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const createNotification = async (
  notification: NotificationsFormData,
): Promise<Notification> => {
  return axios
    .post<Notification>("/notifications", notification, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const deleteNotification = async (
  notificationId: string,
): Promise<void> => {
  return axios
    .delete(`/notifications/${notificationId}`, {
      headers: buildHeaders(),
    })
    .then(() => undefined);
};
