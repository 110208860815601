import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { EditorContent, useEditor } from "@tiptap/react";
import { z } from "zod";
import { postQueryOptions } from "../../../api/posts.api";
import { tiptapExtensions } from "../../../components/tipytap/tipytap-helpers";

function PostPage() {
  const postId = Route.useParams().postId;
  const postQuery = useSuspenseQuery(postQueryOptions(postId));
  const post = postQuery.data;

  const editor = useEditor({
    extensions: tiptapExtensions,
    editable: false,
    // biome-ignore lint: @typescript-eslint/no-explicit-any
    content: post.tiptapJson as any,
  });

  return (
    <div className="min-h-screen bg-white md:bg-gray-700 md:py-8 ">
      <div className="w-full md:max-w-4xl mx-auto md:py-4">
        <div className="bg-white md:shadow-lg md:rounded-lg p-4 md:my-10 py-10">
          <EditorContent editor={editor} />
        </div>
      </div>
    </div>
  );
}

const routeParamsSchema = z.object({
  postId: z.string(),
});

export const Route = createFileRoute("/_public/p/$postId")({
  component: () => <PostPage />,
  parseParams: (params) => routeParamsSchema.parse(params),
  loader: ({ context: { queryClient }, params }) =>
    queryClient.ensureQueryData(postQueryOptions(params.postId)),
});
