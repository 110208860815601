import { cn } from "@/lib/utils";
import { Link } from "@tanstack/react-router";
import { PlusCircle } from "lucide-react";
import { buttonVariants } from "../ui/button-shared";

interface TableHeaderBaseProps {
  buttonText: string;
}

interface TableHeaderLinkProps extends TableHeaderBaseProps {
  linkTo: string;
  onClick?: never;
}

interface TableHeaderClickProps extends TableHeaderBaseProps {
  linkTo?: never;
  onClick: () => void;
}

type TableHeaderProps = TableHeaderLinkProps | TableHeaderClickProps;

export function TableHeader({ linkTo, buttonText, onClick }: TableHeaderProps) {
  const ButtonContent = (
    <>
      <PlusCircle className="h-3.5 w-3.5" />
      <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
        {buttonText}
      </span>
    </>
  );

  return (
    <div className="flex justify-end mb-2">
      {linkTo ? (
        <Link
          className={cn(buttonVariants({ size: "sm", className: "h-7 gap-1" }))}
          to={linkTo}
        >
          {ButtonContent}
        </Link>
      ) : (
        // biome-ignore lint/a11y/useButtonType: <explanation>
        <button
          className={cn(buttonVariants({ size: "sm", className: "h-7 gap-1" }))}
          onClick={onClick}
        >
          {ButtonContent}
        </button>
      )}
    </div>
  );
}
