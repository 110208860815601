import { queryOptions } from "@tanstack/react-query";
import type { Post } from "@zora/api/concerns/mobile/mobile.router";
import type { PostsResponse } from "@zora/api/concerns/posts/posts.router";
import type {
  CreateBasicPostInput,
  EditBasicPostInput,
} from "@zora/api/concerns/posts/posts.zod";
import { axios, buildHeaders } from "./axios";

export const postsQueryOptions = queryOptions({
  queryKey: ["posts"],
  queryFn: () => fetchPosts(),
});

export class PostNotFoundError extends Error {}

export const fetchPosts = async () => {
  return axios
    .get<PostsResponse>("/posts", {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const postQueryOptions = (postId: string) =>
  queryOptions({
    queryKey: ["post", postId],
    queryFn: () => fetchPost(postId),
  });

export const fetchPost = async (postId: string) => {
  return axios
    .get<Post>(`/posts/basic/${postId}`, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const createBasicPost = async (data: CreateBasicPostInput) => {
  return axios
    .post<Post>("/posts/basic", data, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const editBasicPost = async (data: EditBasicPostInput) => {
  return axios
    .put<Post>(`/posts/basic/${data.id}`, data, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const deletePost = async (postId: string) => {
  return axios
    .delete(`/posts/${postId}`, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};
