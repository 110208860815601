import {
  createQuickAccessSection,
  editQuickAccessSection,
  removePostFromQuickAccessSection,
  reorderQuickAccessItems,
} from "@/api/quick-access.api";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { usePostablesCommandSearch } from "@/hooks/usePostablesCommandSearch";
import { friendlyPostableTitle, friendlyPostableType } from "@/lib/utils";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useRouter } from "@tanstack/react-router";
import type { Postable } from "@zora/api/concerns/postables/postables.router";
import type { QuickAccessSection } from "@zora/api/concerns/quick-access/quick-access.router";
import {
  type ReorderSectionItemsInput,
  type SectionInput,
  sectionZod,
} from "@zora/api/concerns/quick-access/quick-access.zod";
import debounce from "lodash/debounce";
import { ChevronLeft, MoreHorizontal, PlusCircle } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { PostablesCommandSeach } from "../command/postables-command-search";
import HorizontalMoveIcon from "../icons/horizontal-move-icon";
import { ConfirmModal } from "../ui/confirm-modal";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";

type SectionFormProps =
  | {
      isEdit: true;
      initialValues: QuickAccessSection;
    }
  | {
      isEdit: false;
      initialValues?: QuickAccessSection;
    };

function SortableRow({
  item,
  navigate,
  setRemovePostId,
}: {
  // biome-ignore lint: @typescript-eslint/no-explicit-any
  item: any;
  // biome-ignore lint: @typescript-eslint/no-explicit-any
  navigate: any;
  setRemovePostId: (id: string | null) => void;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item.id });

  const style = {
    transform: transform ? `translate3d(0px, ${transform.y}px, 0)` : undefined,
    transition,
  };

  return (
    <TableRow ref={setNodeRef} style={style} key={item.id}>
      <TableCell className="cursor-move" {...attributes} {...listeners}>
        <HorizontalMoveIcon className="handle h-4 w-4" />
      </TableCell>
      <TableCell>{friendlyPostableType(item.postableType)}</TableCell>
      <TableCell>{friendlyPostableTitle(item.postable)}</TableCell>
      <TableCell>{item.order + 1}</TableCell>
      <TableCell>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button aria-haspopup="true" size="icon" variant="ghost">
              <MoreHorizontal className="h-4 w-4" />
              <span className="sr-only">Toggle menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            {item.id.includes("fake-") ? null : (
              <DropdownMenuItem
                onClick={() => {
                  navigate({ to: `/app/feed/sections/${item.id}` });
                }}
              >
                Edit
              </DropdownMenuItem>
            )}
            <DropdownMenuItem
              onClick={() => {
                setRemovePostId(item.id);
              }}
            >
              Remove
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </TableCell>
    </TableRow>
  );
}

export function SectionForm({ isEdit, initialValues }: SectionFormProps) {
  const router = useRouter();
  const navigate = useNavigate();
  const [removePostId, setRemovePostId] = useState<string | null>(null);
  const setOpen = usePostablesCommandSearch((state) => state.setIsOpen);
  const [postables, setPostables] = useState(initialValues?.postables || []);
  const sectionId = initialValues?.id;
  const queryClient = useQueryClient();

  const form = useForm<SectionInput>({
    resolver: zodResolver(sectionZod),
    defaultValues: {
      title: initialValues?.title || "",
      // biome-ignore lint: @typescript-eslint/no-explicit-any
      displayType: (initialValues?.displayType as any) || "list",
      postables: [],
    },
  });

  const { mutate: createSectionMutation } = useMutation({
    mutationFn: (section: SectionInput) => createQuickAccessSection(section),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["quick-access-sections"] });
      navigate({ to: "/app/quick-access" });
    },
  });

  const { mutate: updateSectionMutation } = useMutation({
    mutationFn: (section: SectionInput & { id: string }) =>
      editQuickAccessSection(section),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["quick-access-sections"] });
      if (sectionId)
        queryClient.invalidateQueries({
          queryKey: ["quick-access-sections", sectionId],
        });
      navigate({ to: "/app/quick-access" });
    },
  });

  const { mutateAsync, isPending: deleteLoading } = useMutation({
    mutationFn: ({
      sectionId,
      postId,
    }: {
      sectionId: string;
      postId: string;
    }) => removePostFromQuickAccessSection(sectionId, postId),
  });

  const { mutateAsync: reorderItems } = useMutation({
    mutationFn: (items: ReorderSectionItemsInput & { sectionId: string }) =>
      reorderQuickAccessItems(items),
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const debouncedUpdateOrder = debounce((sectionId, items) => {
    reorderItems({ sectionId, items }).catch((e) => {
      console.error(e);
    });
  }, 2500);

  const handleDragEnd = (event: {
    active: { id: number | string };
    over: { id: number | string } | null;
  }) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      setPostables((currentItems) => {
        const oldIndex = currentItems.findIndex(
          (item) => item.id === active.id,
        );
        const newIndex = currentItems.findIndex((item) => item.id === over.id);
        const newItems = arrayMove(currentItems, oldIndex, newIndex);

        const updatedItems = newItems.map((item, index) => {
          return { ...item, order: index };
        });

        if (sectionId) debouncedUpdateOrder(sectionId, updatedItems);

        return updatedItems;
      });
    }
  };

  const onSubmit = (data: SectionInput) => {
    if (isEdit && sectionId) {
      updateSectionMutation({
        ...data,
        id: sectionId,
        postables: postables.map((item) => ({
          ...item,
          id: item.id.includes("fake-") ? undefined : item.id,
          deleted: false,
        })),
      });
    } else {
      createSectionMutation({
        ...data,
        postables: postables.map((item) => ({
          ...item,
          id: item.id.includes("fake-") ? undefined : item.id,
          deleted: false,
        })),
      });
    }
  };

  return (
    <div className="mx-auto grid max-w-[59rem] flex-1 auto-rows-max gap-4 mt-4">
      <PostablesCommandSeach
        onItemSelect={(item: Postable) => {
          const itemToPostable = {
            id: `fake-${postables.length + 1}`,
            ...item,
            organizationId: initialValues?.organizationId || "",
            quickAccessSectionId: initialValues?.id || "",
            order: postables.length,
            json: {},
          };
          setPostables((currentItems) => {
            return [...currentItems, itemToPostable];
          });
          setOpen(false);
        }}
        existingIds={postables.map((p) => p.postableId)}
        filters={{ types: ["webLink", "nativeScreen"] }}
      />
      <ConfirmModal
        open={Boolean(removePostId)}
        onConfirm={() => {
          const fake = removePostId?.includes("fake-");
          if (removePostId && initialValues?.id && !fake) {
            mutateAsync({ sectionId: initialValues?.id, postId: removePostId })
              .then(() => {
                setPostables((currentItems) => {
                  return currentItems.filter(
                    (item) => item.id !== removePostId,
                  );
                });
              })
              .catch(() => {});
          } else if (fake) {
            setPostables((currentItems) => {
              return currentItems.filter((item) => item.id !== removePostId);
            });
          }
          setRemovePostId(null);
        }}
        onDismiss={() => {
          setRemovePostId(null);
        }}
        title="Remove Post from Section"
        description="Are you sure you want to remove this post from the section? The post will not be deleted."
        isLoading={deleteLoading}
      />

      <Form {...form}>
        <div className="flex items-center gap-4">
          <Button
            variant="outline"
            size="icon"
            className="h-7 w-7"
            onClick={() => {
              router.history.back();
            }}
          >
            <ChevronLeft className="h-4 w-4" />
            <span className="sr-only">Back</span>
          </Button>
          <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
            {isEdit ? "Edit Section" : "New Section"}
          </h1>
          {isEdit ? (
            <Badge variant="outline" className="ml-auto sm:ml-0">
              {/* set the logos */}
              {initialValues?.displayType}
            </Badge>
          ) : null}
          <div className="hidden items-center gap-2 md:ml-auto md:flex">
            <Button
              type="button"
              variant="outline"
              size="sm"
              onClick={() => {
                router.history.back();
              }}
            >
              Discard
            </Button>
            <Button size="sm" onClick={form.handleSubmit(onSubmit)}>
              Save Section
            </Button>
          </div>
        </div>

        <div className="grid gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-3 lg:gap-8">
          <div className="grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8">
            <Card x-chunk="dashboard-07-chunk-0">
              <CardHeader>
                <CardTitle>Section</CardTitle>
                <CardDescription>
                  Sections allow you to organize your quick access into
                  sections. Each section can have its own display type allowing
                  you to create a unique experience for your city.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid gap-6">
                  <div className="grid gap-3">
                    <FormField
                      control={form.control}
                      name={"title"}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Title</FormLabel>
                          <FormControl>
                            <Input
                              autoFocus
                              type="text"
                              className="w-full"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card x-chunk="dashboard-07-chunk-1">
              <CardHeader>
                <CardTitle>Items</CardTitle>
                <CardDescription>
                  Add items to display in the section.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                >
                  <SortableContext
                    items={postables}
                    strategy={verticalListSortingStrategy}
                  >
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHead />
                          <TableHead>Type</TableHead>
                          <TableHead>Title</TableHead>
                          <TableHead>Order</TableHead>
                          <TableHead className="w-[100px]" />
                        </TableRow>
                      </TableHeader>

                      <TableBody>
                        {postables.map((item) => (
                          <SortableRow
                            item={item}
                            key={item.id}
                            navigate={navigate}
                            setRemovePostId={setRemovePostId}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </SortableContext>
                </DndContext>
              </CardContent>
              <CardFooter className="justify-center border-t p-4">
                <Button
                  size="sm"
                  variant="ghost"
                  className="gap-1"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <PlusCircle className="h-3.5 w-3.5" />
                  Add Item
                </Button>
              </CardFooter>
            </Card>
          </div>
          <div className="grid auto-rows-max items-start gap-4 lg:gap-8">
            <Card x-chunk="dashboard-07-chunk-3">
              <CardHeader>
                <CardTitle>Display Type</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid gap-6">
                  <div className="grid gap-3">
                    <FormField
                      control={form.control}
                      name="displayType"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Types</FormLabel>
                          <Select
                            onValueChange={field.onChange}
                            defaultValue={field.value}
                          >
                            <FormControl>
                              <SelectTrigger
                                id="displayType"
                                aria-label="Select display"
                              >
                                <SelectValue placeholder="Select display" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              <SelectItem value="tiles">Tiles</SelectItem>
                              <SelectItem value="list">List</SelectItem>
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
        <div className="flex items-center justify-center gap-2 md:hidden">
          <Button variant="outline" size="sm">
            Discard
          </Button>
          <Button size="sm" onClick={form.handleSubmit(onSubmit)}>
            Save Category
          </Button>
        </div>
      </Form>
    </div>
  );
}
