import { queryOptions } from "@tanstack/react-query";
import type {
  WebLink,
  WebLinks,
} from "@zora/api/concerns/weblinks/weblinks.router";
import type { WebLinkInput } from "@zora/api/concerns/weblinks/weblinks.zod";
import { axios, buildHeaders } from "./axios";

export const fetchWebLinks = async () => {
  return axios
    .get<WebLinks>("/weblinks", {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const webLinksQueryOptions = queryOptions({
  queryKey: ["weblinks"],
  queryFn: () => fetchWebLinks(),
});

export const fetchWebLink = async (webLinkId: string) => {
  return axios
    .get<WebLink>(`/weblinks/${webLinkId}`, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const webLinkQueryOptions = (webLinkId: string) =>
  queryOptions({
    queryKey: ["weblink", webLinkId],
    queryFn: () => fetchWebLink(webLinkId),
  });

export const createWebLink = async (webLink: WebLinkInput) => {
  return axios
    .post<WebLink>("/weblinks", webLink, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const editWebLink = async (webLink: WebLinkInput & { id: string }) => {
  return axios
    .put<WebLink>(`/weblinks/${webLink.id}`, webLink, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const deleteWebLink = async (webLinkId: string) => {
  return axios
    .delete(`/weblinks/${webLinkId}`, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};
