import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Spinner } from "@/components/ui/spinner";
import { useMutation } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { useCallback, useEffect, useRef, useState } from "react";

export function FacebookLoginSuccess() {
  const [isSuccess, setIsSuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const hasExchangedCodeRef = useRef(false);

  const exchangeCodeMutation = useMutation({
    mutationFn: async (code: string) => {
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/webhooks/facebook/exchange-code`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            code,
            redirectUri: `${
              import.meta.env.VITE_API_URL
            }/settings/facebook-success`,
          }),
        },
      );
      if (!response.ok) {
        throw new Error("Failed to exchange token");
      }
      return response.json();
    },
    // biome-ignore lint: @typescript-eslint/no-unused-vars
    onSuccess: (data) => {
      setIsSuccess(true);
      setShowModal(true);
    },
    onError: (error) => {
      console.error("Error:", error);
      setIsSuccess(false);
    },
  });

  const exchangeCode = useCallback(
    (code: string) => {
      if (hasExchangedCodeRef.current) return;
      exchangeCodeMutation.mutate(code);
      hasExchangedCodeRef.current = true;
    },
    [exchangeCodeMutation],
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get("code");
    const error = searchParams.get("error");

    if (error) {
      console.error("Login error:", error);
    } else if (code) {
      exchangeCode(code);
    }
  }, [exchangeCode]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900/50">
      <Card className="w-[350px]">
        <CardHeader>
          <CardTitle className="text-center">Facebook Login</CardTitle>
        </CardHeader>
        <CardContent className="flex flex-col items-center">
          {!isSuccess && (
            <>
              <Spinner />
              <p className="text-center text-muted-foreground">
                Processing login...
              </p>
            </>
          )}
          {isSuccess && (
            <p className="text-center text-muted-foreground">All done!</p>
          )}
        </CardContent>
      </Card>

      <Dialog open={showModal} onOpenChange={setShowModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Login Successful</DialogTitle>
          </DialogHeader>
          <p>Your Facebook login was successful!</p>
          <DialogFooter>
            <Button onClick={() => setShowModal(false)}>Close</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export const Route = createFileRoute("/_public/facebook/login/success")({
  component: () => <FacebookLoginSuccess />,
});
