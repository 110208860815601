"use client";

import { fetchConversations } from "@/api/conversations.api";
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command";
import { useQuery } from "@tanstack/react-query";
import { useRouter } from "@tanstack/react-router";
import { Plus, Search, Users } from "lucide-react";
import { useMemo, useState } from "react";
import { create } from "zustand";
import { Button } from "../ui/button";
import { DialogFooter, DialogHeader, DialogTitle } from "../ui/dialog";
import { PhoneInput } from "../ui/phone-input";
import { useConversations } from "./use-conversations";

export interface NewMessageCommand {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const useNewMessageCommand = create<NewMessageCommand>((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) => set({ isOpen }),
}));

export function NewMessageCommand() {
  const router = useRouter();
  const [newConvoInput, setNewConvoInput] = useState<string>("");
  const [isNewConvoMode, setIsNewConvoMode] = useState<boolean>(false);
  const participantId = useConversations((state) => state.participantId);
  const participantType = useConversations((state) => state.participantType);

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
    setTimeout(() => {
      if (!open) {
        setIsNewConvoMode(false);
        setNewConvoInput("");
      }
    }, 100);
  };

  const { data } = useQuery({
    queryKey: ["conversations", participantId],
    queryFn: () =>
      fetchConversations({
        participantId,
        participantType,
      }),
  });

  const handleNewConvoSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (newConvoInput.trim()) {
      router.navigate({
        to: "/conversations/new",
        search: { to: newConvoInput.trim() },
      });
      setIsOpen(false);
    }
  };

  const isOpen = useNewMessageCommand((state) => state.isOpen);
  const setIsOpen = useNewMessageCommand((state) => state.setIsOpen);
  const [searchTerm, setSearchTerm] = useState("");

  const conversations = data;

  const filteredConversations = useMemo(
    () =>
      conversations?.filter((convo) => convo.externalId.includes(searchTerm)),
    [searchTerm, conversations],
  );

  return (
    <CommandDialog open={isOpen} onOpenChange={handleOpenChange}>
      {isNewConvoMode ? (
        <div className="p-4 grid gap-4">
          <DialogHeader>
            <DialogTitle>New Conversation</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleNewConvoSubmit}>
            <PhoneInput
              placeholder="Enter recipient's phone number..."
              value={newConvoInput}
              onChange={(e) => setNewConvoInput(e.target.value)}
              className="w-full mb-4"
            />
            <DialogFooter>
              <Button type="submit" disabled={newConvoInput.length < 14}>
                Start Conversation
              </Button>
            </DialogFooter>
          </form>
        </div>
      ) : (
        <>
          <CommandInput
            placeholder="Search numbers or send a message..."
            value={searchTerm}
            onValueChange={setSearchTerm}
          />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup heading="Actions">
              <CommandItem
                onSelect={() => {
                  router.navigate({
                    to: "/conversations/new",
                    search: { all: true },
                  });
                  setIsOpen(false);
                }}
              >
                <Users className="mr-2 h-4 w-4" />
                <span>Send to All</span>
              </CommandItem>
              <CommandItem
                onSelect={() => {
                  setIsNewConvoMode(true);
                  //   router.navigate({
                  //     to: `/conversations/new`,
                  //   });
                  //   setIsOpen(false);
                }}
              >
                <Plus className="mr-2 h-4 w-4" />
                <span>New Message</span>
              </CommandItem>
            </CommandGroup>
            <CommandSeparator />
            <CommandGroup heading="Contacts">
              {filteredConversations?.map((convo) => (
                <CommandItem
                  key={convo.id}
                  onSelect={() => {
                    router.navigate({
                      to: `/conversations/${convo.id}`,
                    });
                    setIsOpen(false);
                  }}
                >
                  <Search className="mr-2 h-4 w-4" />
                  <span>{convo.externalId}</span>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </>
      )}
    </CommandDialog>
  );
}
