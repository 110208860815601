// This is a dummy file, to make the project work without the AI extension.
import { Extension } from "@tiptap/core";

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export type AiStorage = any;
// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export type Language = any;
// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const tryParseToTiptapHTML = (args: any) => args;
export const Ai = Extension.create({
  name: "aiFree",
});
