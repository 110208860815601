import { createBasicPost, editBasicPost } from "@/api/posts.api";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useRouter } from "@tanstack/react-router";
import type { Post } from "@zora/api/concerns/mobile/mobile.router";
import {
  type CreateBasicPostInput,
  postsZodForm,
} from "@zora/api/concerns/posts/posts.zod";
import { ChevronLeft } from "lucide-react";
import { useForm } from "react-hook-form";
import { ImageInput } from "../image-input/image-input";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Textarea } from "../ui/textarea";

type PostFormProps =
  | {
      isEdit: true;
      initialValues: Post;
    }
  | {
      isEdit: false;
      initialValues?: Post;
    };

export function PostsForm({ isEdit, initialValues }: PostFormProps) {
  const router = useRouter();
  const navigate = useNavigate();
  const postId = initialValues?.id;
  const queryClient = useQueryClient();

  const form = useForm<CreateBasicPostInput>({
    resolver: zodResolver(postsZodForm),
    defaultValues: {
      title: initialValues?.title || "",
      body: initialValues?.body || "",
      image: initialValues?.image || "",
    },
  });

  const { mutate: createPostMutation } = useMutation({
    mutationFn: (post: CreateBasicPostInput) => createBasicPost(post),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
      navigate({ to: "/posts" });
    },
  });

  const { mutate: updatePostMutation } = useMutation({
    mutationFn: (post: CreateBasicPostInput & { id: string }) =>
      editBasicPost(post),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
      if (postId) queryClient.invalidateQueries({ queryKey: ["post", postId] });
      navigate({ to: "/posts" });
    },
  });

  const onSubmit = (data: CreateBasicPostInput) => {
    if (isEdit && postId) {
      updatePostMutation({
        ...data,
        id: postId,
      });
    } else {
      createPostMutation({
        ...data,
      });
    }
  };

  return (
    <div className="mx-auto grid max-w-[59rem] flex-1 auto-rows-max gap-4 mt-4">
      <Form {...form}>
        <div className="flex items-center gap-4">
          <Button
            variant="outline"
            size="icon"
            className="h-7 w-7"
            onClick={() => {
              router.history.back();
            }}
          >
            <ChevronLeft className="h-4 w-4" />
            <span className="sr-only">Back</span>
          </Button>
          <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
            {isEdit ? "Edit Post" : "New Post"}
          </h1>
          <div className="hidden items-center gap-2 md:ml-auto md:flex">
            <Button
              type="button"
              variant="outline"
              size="sm"
              onClick={() => {
                router.history.back();
              }}
            >
              Discard
            </Button>
            <Button size="sm" onClick={form.handleSubmit(onSubmit)}>
              Save Post
            </Button>
          </div>
        </div>

        <div className="grid gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-3 lg:gap-8">
          <div className="grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8">
            <Card x-chunk="dashboard-07-chunk-0">
              <CardHeader>
                <CardTitle>Post</CardTitle>
                <CardDescription>
                  Posts can connect to any website to display content directly
                  in the app.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid gap-6">
                  <div className="grid gap-3 grid-cols-2">
                    <FormField
                      control={form.control}
                      name={"title"}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Title</FormLabel>
                          <FormControl>
                            <Input
                              autoFocus
                              type="text"
                              className="w-full"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="grid gap-3 grid-cols-1">
                    <FormField
                      control={form.control}
                      name={"body"}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Body</FormLabel>
                          <FormControl>
                            <Textarea
                              className="w-full min-h-[200px]"
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="grid auto-rows-max items-start gap-4 lg:gap-8">
            <Card className="overflow-hidden">
              <CardHeader>
                <CardTitle>Cover Image</CardTitle>
                <CardDescription>
                  Select a cover image for your post
                </CardDescription>
              </CardHeader>
              <CardContent>
                <FormField
                  name={"image"}
                  control={form.control}
                  render={({ field }) => (
                    <ImageInput
                      form={form}
                      field={field}
                      contentType="image/*"
                      displayType="card"
                    />
                  )}
                />
              </CardContent>
            </Card>
          </div>
        </div>
        <div className="flex items-center justify-center gap-2 md:hidden">
          <Button variant="outline" size="sm">
            Discard
          </Button>
          <Button size="sm" onClick={form.handleSubmit(onSubmit)}>
            Save Post
          </Button>
        </div>
      </Form>
    </div>
  );
}
