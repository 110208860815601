import { z } from "zod";
import { zEmptyStrToUndefined } from "../../lib/zod-helpers.js";

export const webLinkZod = z.object({
  fullTitle: z.string().min(1, { message: "Full title is required" }),
  shortTitle: z.string().min(1, { message: "Short title is required" }),
  url: z.string().min(1, { message: "URL is required" }),
  // image: z.string().min(1, { message: "Image is required" }),
  icon: z.string().min(1, { message: "Icon is required" }),
  color: z.string().optional(),
});

export type WebLinkInput = z.infer<typeof webLinkZod>;
