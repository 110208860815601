import { Link, createFileRoute } from "@tanstack/react-router";

import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";

const navItems = [
  {
    name: "Links",
    artist:
      "Links allow you to curate the web pages that are important to your city. This can load anything on the internet into your mobile app.",
    cover: "https://storage.googleapis.com/csmz-p/purp-geo.png",
    route: "/web-links",
  },
  {
    name: "Events",
    artist:
      "Events allow you to create, manage, and promote upcoming events directly within the app.",
    cover: "https://storage.googleapis.com/csmz-p/red-geo.png",
    route: "/calendar/default/events",
  },
  {
    name: "Posts",
    artist:
      "Posts will natively load into the app now, providing a seamless experience for users to engage with content.",
    cover: "https://storage.googleapis.com/csmz-p/blue-geo.png",
    route: "/posts",
  },
  {
    name: "Places",
    artist:
      "Places allow you to create, manage, and promote upcoming events directly within the app.",
    cover: "https://storage.googleapis.com/csmz-p/geo1.png",
    route: "/places",
  },
];

const madeForYouAlbums = [
  {
    name: "Feed",
    artist:
      "The feed is the mobile app home screen. Manage categories to display the content the way it looks best.",
    cover: "https://storage.googleapis.com/csmz-p/green-geo.png",
    route: "/app/feed",
  },
  {
    name: "Quick Access",
    artist:
      "Quick access allows you to create a list of links that are always available in the app.",
    cover: "https://storage.googleapis.com/csmz-p/yellow-geo.png",
    route: "/app/quick-access",
  },
];

function AlbumArtwork({
  album,
  aspectRatio = "portrait",
  width,
  height,
  className,
  ...props // biome-ignore lint: @typescript-eslint/no-explicit-any
}: any) {
  return (
    <Link to={album.route} className={cn("space-y-3", className)} {...props}>
      <div className={cn("space-y-3", className)} {...props}>
        <div className="overflow-hidden rounded-md">
          <img
            src={album.cover}
            alt={album.name}
            width={width}
            height={height}
            className={cn(
              "h-auto w-auto object-cover transition-all hover:scale-105",
              aspectRatio === "portrait" ? "aspect-[3/4]" : "aspect-square",
            )}
          />
        </div>

        <div className="space-y-1 text-sm">
          <h3 className="font-medium leading-none">{album.name}</h3>
          <p className="text-xs text-muted-foreground">{album.artist}</p>
        </div>
      </div>
    </Link>
  );
}

function Home() {
  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="space-y-1">
          <h2 className="text-2xl font-semibold tracking-tight">
            Create Content
          </h2>
          <p className="text-sm text-muted-foreground">
            Start by adding links, posts, or events.
          </p>
        </div>
      </div>

      <Separator className="my-4" />
      <div className="relative">
        <ScrollArea>
          <div className="flex space-x-4 pb-4">
            {navItems.map((album) => (
              <AlbumArtwork
                key={album.name}
                album={album}
                className="w-[250px]"
                aspectRatio="portrait"
                width={250}
                height={330}
              />
            ))}
          </div>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </div>

      <div className="mt-6 space-y-1">
        <h2 className="text-2xl font-semibold tracking-tight">Curate App</h2>
        <p className="text-sm text-muted-foreground">
          Once you've added your content organize it in your mobile app.
        </p>
      </div>
      <Separator className="my-4" />
      <div className="relative">
        <ScrollArea>
          <div className="flex space-x-4 pb-4">
            {madeForYouAlbums.map((album) => (
              <AlbumArtwork
                key={album.name}
                album={album}
                className="w-[150px]"
                aspectRatio="square"
                width={150}
                height={150}
              />
            ))}
          </div>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </div>
    </div>
  );
}

export const Route = createFileRoute("/_authenticated/home/")({
  component: Home,
});
