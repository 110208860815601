import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export interface PushTokenState {
  pushTokens: string[];
  addPushToken: (token: string) => void;
  removePushToken: (token: string) => void;
  clearPushTokens: () => void;
}

export const usePushTokens = create(
  persist<PushTokenState>(
    (set) => ({
      pushTokens: [],
      addPushToken: (token: string) =>
        set((state) => ({
          pushTokens: [...new Set([...state.pushTokens, token])],
        })),
      removePushToken: (token: string) =>
        set((state) => ({
          pushTokens: state.pushTokens.filter((t) => t !== token),
        })),
      clearPushTokens: () => set({ pushTokens: [] }),
    }),
    {
      name: "push-tokens-storage",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
