import type { PresignUrlOutput } from "@zora/api/concerns/cloud-storage/cloud-storage.router";
import type { PresignedUrlInput } from "@zora/api/concerns/cloud-storage/cloud-storage.zod";
import axiosBase from "axios";
import { axios, buildHeaders } from "./axios";

export const presignedUrl = async (data: PresignedUrlInput) => {
  return axios
    .post<PresignUrlOutput>("/cloud-storage", data, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const uploadToPresignedUrl = async (url: string, file: File) => {
  return axiosBase.put(url, file, {
    headers: {
      "Content-Type": file.type,
    },
  });
};
