import { deleteIncident, incidentsQueryOptions } from "@/api/incidents.api";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import type { Incident } from "@zora/api/concerns/incidents/incidents.router";
import dayjs from "dayjs";
import { MoreHorizontal } from "lucide-react";
import { useState } from "react";
import { ConfirmModal } from "../ui/confirm-modal";

function IncidentRow({
  incident,
  navigate,
  setDeleteIncidentId,
}: {
  incident: Incident;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  navigate: any;
  setDeleteIncidentId: (id: string | null) => void;
}) {
  return (
    <TableRow>
      <TableCell className="font-medium">{incident.description}</TableCell>
      <TableCell>
        {incident.imageUrl ? (
          <img src={incident.imageUrl} alt="Incident" className="w-6 h-6" />
        ) : (
          "N/A"
        )}
      </TableCell>
      <TableCell>{incident.latitude ?? "N/A"}</TableCell>
      <TableCell>{incident.longitude ?? "N/A"}</TableCell>
      <TableCell>{incident.address ?? "N/A"}</TableCell>
      <TableCell>
        {incident.resolvedAt && !dayjs(incident.resolvedAt).isSame(dayjs(0))
          ? dayjs(incident.resolvedAt).format("YYYY-MM-DD HH:mm:ss")
          : ""}
      </TableCell>
      <TableCell>{incident.audience.fullName ?? "N/A"}</TableCell>
      <TableCell>
        {dayjs(incident.createdAt).format("YYYY-MM-DD HH:mm:ss")}
      </TableCell>
      <TableCell>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button aria-haspopup="true" size="icon" variant="ghost">
              <MoreHorizontal className="h-4 w-4" />
              <span className="sr-only">Toggle menu</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuItem
              onClick={() => {
                navigate({
                  to: `/incidents/${incident.id}`,
                });
              }}
            >
              Edit
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => setDeleteIncidentId(incident.id)}>
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </TableCell>
    </TableRow>
  );
}

export default function IncidentsTable() {
  const incidentsQuery = useSuspenseQuery(incidentsQueryOptions);
  const incidents = incidentsQuery.data;
  const [deleteIncidentId, setDeleteIncidentId] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const { mutate: deleteIncidentMutation, isPending: isLoading } = useMutation({
    mutationFn: deleteIncident,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["incidents"] });
      setDeleteIncidentId(null);
    },
  });

  const navigate = useNavigate();

  return (
    <Card>
      <CardHeader>
        <CardTitle>Incidents</CardTitle>
        <CardDescription>Manage your incidents.</CardDescription>
      </CardHeader>
      <ConfirmModal
        open={Boolean(deleteIncidentId)}
        onConfirm={() => {
          if (deleteIncidentId) deleteIncidentMutation(deleteIncidentId);
        }}
        onDismiss={() => {
          setDeleteIncidentId(null);
        }}
        isLoading={isLoading}
      />
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Description</TableHead>
              <TableHead>Image</TableHead>
              <TableHead>Latitude</TableHead>
              <TableHead>Longitude</TableHead>
              <TableHead>Address</TableHead>
              <TableHead>Resolved At</TableHead>
              <TableHead>Resident</TableHead>
              <TableHead>Created At</TableHead>
              <TableHead>
                <span className="sr-only">Actions</span>
              </TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {incidents.map((incident) => (
              <IncidentRow
                key={incident.id}
                incident={incident}
                navigate={navigate}
                setDeleteIncidentId={setDeleteIncidentId}
              />
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <CardFooter>
        <div className="text-xs text-muted-foreground">
          Showing <strong>1-{incidents.length}</strong> of{" "}
          <strong>{incidents.length}</strong> incidents
        </div>
      </CardFooter>
    </Card>
  );
}
