import { useState } from "react";
import type { UseFormReturn } from "react-hook-form";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { FormMessage } from "../ui/form";
import { Input } from "../ui/input";
import { featherIcons } from "./icons";

export function IconSelector({
  form,
}: {
  // biome-ignore lint: @typescript-eslint/no-explicit-any
  form: UseFormReturn<any>;
}) {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredIcons = featherIcons.filter((icon) =>
    icon.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <Card className="overflow-hidden">
      <CardHeader className="flex flex-row justify-between">
        <div>
          <CardTitle>Icon Selection</CardTitle>
          <CardDescription>Select an icon for your web link</CardDescription>
        </div>
        {form.watch("icon") && (
          <div className="flex items-center">
            <img
              src={`/icons/feather/${form.watch("icon")}.svg`}
              alt={form.watch("icon")}
              className="w-6 h-6"
            />
            <span className="ml-2">{form.watch("icon")}</span>
          </div>
        )}
      </CardHeader>
      <CardContent>
        <div className="grid gap-2">
          <Input
            type="text"
            placeholder="Search for an icon..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {form.formState.errors.icon && (
            <FormMessage>
              {form.formState.errors.icon.message?.toString()}
            </FormMessage>
          )}
          <div
            className="grid grid-cols-6 gap-2 overflow-y-auto mt-4"
            style={{ maxHeight: "200px" }}
          >
            {filteredIcons.map((icon) => (
              // biome-ignore lint/a11y/useButtonType: <explanation>
              <button
                key={icon}
                onClick={() => form.setValue("icon", icon)}
                className={`p-2 text-center rounded-lg ${
                  form.watch("icon") === icon
                    ? "border-2 border-blue-500"
                    : "border"
                }`}
              >
                <img
                  src={`/icons/feather/${icon}.svg`}
                  alt={icon}
                  className="mx-auto"
                />
                <span>{icon}</span>
              </button>
            ))}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
