import { z } from "zod";

export const placeZodForm = z.object({
  title: z
    .string()
    .min(1, "Title is required")
    .max(255, "Title cannot exceed 255 characters"),
  description: z.string().min(1, "Description is required"),
  type: z.string().min(1, "Type is required"),
  latitude: z.coerce
    .number()
    .min(-90)
    .max(90)
    .refine(
      (val) => val !== 0,
      "Latitude cannot be 0. Geocode the address to set the latitude and longitude.",
    ),
  longitude: z.coerce
    .number()
    .min(-180)
    .max(180)
    .refine(
      (val) => val !== 0,
      "Longitude cannot be 0. Geocode the address to set the latitude and longitude.",
    ),
  address: z.string().min(1, "Address is required"),
  media_id: z.string().optional(),
  image_url: z.string().url("Must be a valid URL").optional(),
});

export const initialRegionZod = z.object({
  initialRegion: z.object({
    latitude: z.number(),
    longitude: z.number(),
    latitudeDelta: z.number(),
    longitudeDelta: z.number(),
  }),
});

export type PlaceFormData = z.infer<typeof placeZodForm>;

export const geocodeZodForm = z.object({
  address: z.string().min(1, "Address is required"),
});

export type GeocodeFormData = z.infer<typeof geocodeZodForm>;
