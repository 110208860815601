import { queryOptions } from "@tanstack/react-query";
import type {
  QuickAccessSection,
  QuickAccessSections,
} from "@zora/api/concerns/quick-access/quick-access.router";
import "@zora/api/concerns/quick-access/quick-access.zod";
import type {
  ReorderSectionItemsInput,
  ReorderSectionsInput,
  SectionInput,
} from "@zora/api/concerns/quick-access/quick-access.zod";
import { axios, buildHeaders } from "./axios";

export const fetchQuickAccessList = async () => {
  return axios
    .get<QuickAccessSections>("/quick-access", {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const reorderQuickAccessSection = async (
  quickAccess: ReorderSectionsInput,
) => {
  return axios
    .post<{ success: boolean }>("/quick-access/sections/reorder", quickAccess, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const sectionQueryOptions = (sectionId: string) =>
  queryOptions({
    queryKey: ["quick-access-sections", sectionId],
    queryFn: ({ queryKey }) => fetchQuickAccessSection(queryKey[1]),
  });

export const fetchQuickAccessSection = async (sectionId: string) => {
  return axios
    .get<QuickAccessSection>(`/quick-access/sections/${sectionId}`, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const removePostFromQuickAccessSection = async (
  sectionId: string,
  postId: string,
) => {
  return axios
    .delete(`/quick-access/sections/${sectionId}/items/${postId}`, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const reorderQuickAccessItems = async (
  items: ReorderSectionItemsInput & { sectionId: string },
) => {
  return axios
    .post(`/quick-access/sections/${items.sectionId}/items/reorder`, items, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const createQuickAccessSection = async (section: SectionInput) => {
  return axios
    .post<QuickAccessSection>("/quick-access/sections", section, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const editQuickAccessSection = async (
  section: SectionInput & { id: string },
) => {
  return axios
    .put<QuickAccessSection>(`/quick-access/sections/${section.id}`, section, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};

export const deleteQuickAccessSection = async (sectionId: string) => {
  return axios
    .delete(`/quick-access/sections/${sectionId}`, {
      headers: buildHeaders(),
    })
    .then((r) => r.data);
};
