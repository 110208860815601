/* eslint-disable react-refresh/only-export-components */
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";

import { Info } from "lucide-react";

import { cn } from "@/lib/utils";

import { Link } from "@tanstack/react-router";
import type { ConversationResponse } from "@zora/api/concerns/conversations/conversations.router";
import { buttonVariants } from "../ui/button-shared";
import { ExpandableChatHeader } from "../ui/chat/expandable-chat";

interface ChatTopbarProps {
  participants?: ConversationResponse["participants"];
}

// { icon: Phone }, { icon: Video },
export const TopbarIcons = [{ icon: Info }];

export default function ChatTopbar({ participants }: ChatTopbarProps) {
  const participant = participants?.find(
    (participant) => participant.participantType === "audience",
  );

  const id =
    participant?.details?.name || participant?.details?.identifier || "Unknown";

  return (
    <ExpandableChatHeader>
      <div className="flex items-center gap-2">
        <Avatar className="flex justify-center items-center">
          <AvatarImage
            src={undefined}
            alt={undefined}
            width={6}
            height={6}
            className="w-10 h-10 "
          />
          <AvatarFallback> {id[0]} </AvatarFallback>
        </Avatar>
        <div className="flex flex-col">
          <span className="font-medium">{id}</span>
          <span className="text-xs">Active 2 mins ago</span>
        </div>
      </div>

      <div className="flex gap-1">
        {TopbarIcons.map((icon, index) => (
          <Link
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            key={index}
            href="#"
            className={cn(
              buttonVariants({ variant: "ghost", size: "icon" }),
              "h-9 w-9",
            )}
          >
            <icon.icon size={20} className="text-muted-foreground" />
          </Link>
        ))}
      </div>
    </ExpandableChatHeader>
  );
}
