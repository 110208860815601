import type { EventInput } from "@zora/api/concerns/events/events.zod";
import { create } from "zustand";

export interface EventsModalState {
  isOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  defaultValues: Partial<EventInput>;
  setDefaultValues: (values: Partial<EventInput>) => void;
  eventId: string | null;
  setEventId: (id: string | null) => void;
}

export const useEventsModal = create<EventsModalState>((set) => ({
  isOpen: false,
  setIsModalOpen: (isOpen: boolean) => set(() => ({ isOpen })),
  defaultValues: {},
  setDefaultValues: (values: Partial<EventInput>) =>
    set(() => ({ defaultValues: values })),
  eventId: null,
  setEventId: (id: string | null) => set(() => ({ eventId: id })),
}));
