import { selectOrganization } from "@/api/auth.api";
import type { DropdownItem } from "@/components/tables/data-row";
import { DataTable } from "@/components/tables/data-table";
import { useAuth } from "@/hooks/useAuth";
import { useMutation } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import type { ColumnDef } from "@tanstack/react-table";

const columns: ColumnDef<{ name: string; id: string }>[] = [
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "name",
    header: "Name",
  },
];

function Organizations() {
  const claims = useAuth((c) => c.claims);
  const setJwt = useAuth((state) => state.setJwt);
  const setClaims = useAuth((state) => state.setClaims);
  const navigate = useNavigate();

  const organizations =
    claims?.organizations?.map((o) => ({ name: o.name, id: o.id })) ?? [];

  const { mutate: selectOrganizationMutation } = useMutation({
    mutationFn: selectOrganization,
    onSuccess: (data) => {
      setJwt(data.jwt);
      setClaims(data.claims);

      navigate({
        to: "/home",
      });
    },
  });

  const dropdownItems: DropdownItem<(typeof organizations)[0]>[] = [
    {
      label: "Select",
      onClick: (organization) => {
        selectOrganizationMutation(organization.id);
      },
    },
  ];

  return (
    <div>
      <DataTable
        data={organizations}
        columns={columns}
        title="Organizations"
        description="Select your organization"
        dropdownItems={dropdownItems}
      />
    </div>
  );
}

export const Route = createFileRoute("/_authenticated/settings/organizations")({
  component: () => <Organizations />,
});
