import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useRouter } from "@tanstack/react-router";
import { ChevronLeft } from "lucide-react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import type { z } from "zod";

import { createPlace, geocode, updatePlace } from "@/api/places.api";
import { placeZodForm } from "@zora/api/concerns/places/places.zod";
import { ImageInput } from "../image-input/image-input";
import { Icons } from "../ui/icons";

type PlaceFormData = z.infer<typeof placeZodForm>;

type PlaceFormProps =
  | {
      isEdit: true;
      initialValues: PlaceFormData & { id: string };
    }
  | {
      isEdit: false;
      initialValues?: Partial<PlaceFormData>;
    };

export function PlaceForm({ isEdit, initialValues }: PlaceFormProps) {
  const router = useRouter();
  const navigate = useNavigate();
  const placeId = isEdit ? initialValues.id : undefined;
  const queryClient = useQueryClient();

  const form = useForm<PlaceFormData>({
    resolver: zodResolver(placeZodForm),
    defaultValues: {
      title: initialValues?.title || "",
      description: initialValues?.description || "",
      type: initialValues?.type || "",
      latitude: initialValues?.latitude || 0,
      longitude: initialValues?.longitude || 0,
      address: initialValues?.address || "",
    },
  });

  const { mutate: geocodeMutation, isPending: geocodeLoading } = useMutation({
    mutationFn: (address: string) => geocode(address),
    onSuccess: (data) => {
      form.setValue("latitude", data?.latitude || 0);
      form.setValue("longitude", data?.longitude || 0);
      if (data?.latitude && data?.longitude)
        form.clearErrors(["latitude", "longitude"]);
    },
  });

  const { mutate: createPlaceMutation, isPending: isCreatingPlace } =
    useMutation({
      mutationFn: (place: PlaceFormData) => createPlace(place),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["places"] });
        navigate({ to: "/places" });
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const { mutate: updatePlaceMutation, isPending: isUpdatingPlace } =
    useMutation({
      mutationFn: (place: PlaceFormData & { id: string }) => updatePlace(place),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["places"] });
        if (placeId)
          queryClient.invalidateQueries({
            queryKey: ["place", placeId],
          });
        navigate({ to: "/places" });
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const isSubmitting = isCreatingPlace || isUpdatingPlace;

  const onSubmit = (data: PlaceFormData) => {
    if (isEdit && placeId) {
      updatePlaceMutation({
        ...data,
        id: placeId,
      });
    } else {
      createPlaceMutation(data);
    }
  };

  return (
    <div className="mx-auto grid max-w-[59rem] flex-1 auto-rows-max gap-4 mt-4">
      <Form {...form}>
        <div className="flex items-center gap-4">
          <Button
            variant="outline"
            size="icon"
            className="h-7 w-7"
            onClick={() => {
              router.history.back();
            }}
          >
            <ChevronLeft className="h-4 w-4" />
            <span className="sr-only">Back</span>
          </Button>
          <h1 className="flex-1 shrink-0 whitespace-nowrap text-xl font-semibold tracking-tight sm:grow-0">
            {isEdit ? "Edit Place" : "New Place"}
          </h1>
          <div className="hidden items-center gap-2 md:ml-auto md:flex">
            <Button
              type="button"
              variant="outline"
              size="sm"
              onClick={() => {
                router.history.back();
              }}
            >
              Discard
            </Button>
            <Button
              size="sm"
              onClick={form.handleSubmit(onSubmit)}
              disabled={isSubmitting}
            >
              {isSubmitting ? <Icons.spinner className="h-4 w-4 mr-2" /> : null}
              Save Place
            </Button>
          </div>
        </div>

        <div className="grid gap-4 md:grid-cols-[1fr_250px] lg:grid-cols-3 lg:gap-8">
          <div className="grid auto-rows-max items-start gap-4 lg:col-span-2 lg:gap-8">
            <Card>
              <CardHeader>
                <CardTitle>Place Details</CardTitle>
                <CardDescription>
                  Enter the details for your place.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid gap-6">
                  <FormField
                    control={form.control}
                    name="type"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Type</FormLabel>
                        <Select
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                        >
                          <FormControl>
                            <SelectTrigger id="type" aria-label="Select type">
                              <SelectValue placeholder="Select type" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            <SelectItem value="park">Park</SelectItem>
                            <SelectItem value="restaurant">
                              Restaurant
                            </SelectItem>
                            <SelectItem value="city-hall">City Hall</SelectItem>
                            <SelectItem value="service">Service</SelectItem>
                            <SelectItem value="retail">Retail</SelectItem>
                            <SelectItem value="entertainment">
                              Entertainment
                            </SelectItem>
                            <SelectItem value="other">Other</SelectItem>
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className="grid gap-4 sm:grid-cols-2">
                    <FormField
                      control={form.control}
                      name="title"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Place Title</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="description"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Description</FormLabel>
                          <FormControl>
                            <Input {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <FormField
                    control={form.control}
                    name="address"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Address</FormLabel>
                        <div className="flex gap-2">
                          <FormControl className="flex-grow">
                            <Input {...field} />
                          </FormControl>
                          <Button
                            disabled={!field.value}
                            type="button"
                            size="sm"
                            variant="outline"
                            onClick={() => {
                              geocodeMutation(field.value);
                            }}
                          >
                            {geocodeLoading ? (
                              <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
                            ) : null}
                            Geocode
                          </Button>
                        </div>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="grid auto-rows-max items-start gap-4 lg:gap-8">
            <Card className="overflow-hidden">
              <CardHeader>
                <CardTitle>Location</CardTitle>
                <CardDescription>
                  Geocode an address to set the latitude and longitude.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <FormField
                  control={form.control}
                  name="latitude"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Latitude</FormLabel>
                      <FormControl>
                        <Input {...field} type="number" step="any" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="longitude"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Longitude</FormLabel>
                      <FormControl>
                        <Input {...field} type="number" step="any" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </CardContent>
            </Card>
            <Card className="overflow-hidden">
              <CardHeader>
                <CardTitle>Cover Image</CardTitle>
                <CardDescription>
                  Select a cover image for your post
                </CardDescription>
              </CardHeader>
              <CardContent>
                <FormField
                  name={"image_url"}
                  control={form.control}
                  render={({ field }) => (
                    <ImageInput
                      form={form}
                      field={field}
                      contentType="image/*"
                      displayType="card"
                    />
                  )}
                />
              </CardContent>
            </Card>
          </div>
        </div>

        <div className="flex items-center justify-center gap-2 md:hidden mt-4">
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              router.history.back();
            }}
          >
            Discard
          </Button>
          <Button
            size="sm"
            onClick={form.handleSubmit(onSubmit)}
            disabled={isSubmitting}
          >
            {isSubmitting ? <Icons.spinner className="h-4 w-4 mr-2" /> : null}
            Save Place
          </Button>
        </div>
      </Form>
    </div>
  );
}
