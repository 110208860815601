import { notificationsQueryOptions } from "@/api/notifications.api";
import { deleteNotification } from "@/api/notifications.api";
import { TableHeader } from "@/components/tables/add-header";
import type { DropdownItem } from "@/components/tables/data-row";
import { DataTable } from "@/components/tables/data-table";
import { ConfirmModal } from "@/components/ui/confirm-modal";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import type { ColumnDef } from "@tanstack/react-table";
import dayjs from "@zora/api/lib/dayjs";
import type { Notification } from "@zora/api/prisma/generated";
import { useState } from "react";

const columns: ColumnDef<Notification>[] = [
  {
    accessorKey: "title",
    header: "Title",
  },
  {
    accessorKey: "body",
    header: "Body",
  },
  {
    accessorKey: "createdAt",
    header: "Created At",
    cell: ({ row }) => {
      return dayjs(row.original.createdAt).format("MM/DD/YYYY hh:mm A");
    },
  },
];

function NotificationsTable({
  dropdownItems,
}: {
  dropdownItems: DropdownItem<Notification>[];
}) {
  const notificationsQuery = useSuspenseQuery(notificationsQueryOptions);
  const notifications = notificationsQuery.data;

  return (
    <DataTable
      data={notifications.items}
      columns={columns}
      title="Notifications"
      description="Manage your notifications"
      dropdownItems={dropdownItems}
    />
  );
}

function Notifications() {
  // Add state to manage deletion
  const [deleteNotificationId, setDeleteNotificationId] = useState<
    string | null
  >(null);
  const queryClient = useQueryClient();

  // Mutation hook for deleting a notification
  const { mutate: deleteNotificationMutation, isPending: isDeleting } =
    useMutation({
      mutationFn: deleteNotification,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["notifications"] });
        setDeleteNotificationId(null);
      },
    });

  // Update dropdown items to include Delete option
  const dropdownItems: DropdownItem<Notification>[] = [
    {
      label: "Delete",
      onClick: ({ id }) => {
        setDeleteNotificationId(id);
      },
    },
  ];

  return (
    <>
      <div>
        <TableHeader
          linkTo="/notifications/new"
          buttonText="New Notification"
        />
        <NotificationsTable dropdownItems={dropdownItems} />
      </div>
      <ConfirmModal
        open={Boolean(deleteNotificationId)}
        onConfirm={() => {
          if (deleteNotificationId) {
            deleteNotificationMutation(deleteNotificationId);
          }
        }}
        onDismiss={() => {
          setDeleteNotificationId(null);
        }}
        isLoading={isDeleting}
      />
    </>
  );
}

export const Route = createFileRoute("/_authenticated/notifications/")({
  component: Notifications,
});
